import React, { useState } from 'react';
import { ArrowLeftShort, FolderFill } from 'react-bootstrap-icons';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import NetworkEnum from '../../shared/enums/networkEnum';
import { ReactComponent as FacebookIcon } from '../../images/logos/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../images/logos/instagram.svg';
import { ReactComponent as GoogleAdsIcon } from '../../images/logos/googleAds.svg';
import Button from '../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/enums/buttonEnum';
import styles from './TopBar.module.css';

const networkIcons = {
  [NetworkEnum.FACEBOOK]: FacebookIcon,
  [NetworkEnum.INSTAGRAM]: InstagramIcon,
  [NetworkEnum.GOOGLE]: GoogleAdsIcon,
};

const TopBar = ({
  title,
  actions,
  network,
  alternativeBackLink,
  breadCrumbs = [],
}) => {
  const history = useHistory();
  const [isNavigating, setIsNavigating] = useState(false);

  const handleBack = () => {
    if (alternativeBackLink) {
      history.push(alternativeBackLink);
      return;
    }

    if (isNavigating) return;
    setIsNavigating(true);

    const currentPath = window.location.pathname;
    const unsubscribe = history.listen((location) => {
      if (location.pathname === currentPath) {
        window.history.back();
      }

      unsubscribe();
      setTimeout(() => setIsNavigating(false), 100);
    });

    window.history.back();
  };

  const renderNetworkIndicator = () => {
    if (!network) return null;

    const NetworkIcon = networkIcons[network];
    return (
      <div className={styles.networkIndicator}>
        <NetworkIcon width={22} height={22} className={styles.networkIcon} />
        <span className={styles.networkName}>{network}</span>
      </div>
    );
  };

  const finalBreadCrumbs = breadCrumbs.filter(
    (crumb) => crumb.label && crumb.link,
  );

  return (
    <div className={styles.root}>
      <div className={styles.topBarLeft}>
        <Button
          onClick={handleBack}
          className={styles.backButton}
          size={ButtonSizeEnum.S}
          variant={ButtonVariantEnum.TEXT}
          leftIcon={<ArrowLeftShort />}
        >
          Back
        </Button>
        {finalBreadCrumbs && (
          <div className={styles.breadCrumbs}>
            {finalBreadCrumbs.map((crumb, index) => (
              <Link to={crumb.link} className={styles.crumb} key={index}>
                {index > 0 && <FolderFill />}
                <span>{crumb.label}</span> /
              </Link>
            ))}
          </div>
        )}
        <div className={styles.pageTitleContainer}>
          <div className={styles.pageTitle}>{title}</div>
          {renderNetworkIndicator()}
        </div>
      </div>
      <div className={styles.topBarRight}>{actions}</div>
    </div>
  );
};

TopBar.propTypes = {
  title: PropTypes.string,
  actions: PropTypes.node,
  network: PropTypes.oneOf(Object.values(NetworkEnum)),
};

TopBar.defaultProps = {
  title: null,
  actions: null,
  network: null,
};

export default TopBar;
