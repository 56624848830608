import React from 'react';
import {
  Check2Circle,
  ExclamationTriangle,
  ExclamationOctagon,
  InfoSquare,
} from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import Button from '../../Components/FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../shared/enums/buttonEnum';
import styles from './MessageBox.module.css';

const MessageBox = ({
  message,
  isError,
  isWarning,
  isSuccess,
  className,
  buttonLabel,
  buttonRoute,
}) => {
  const Icon = isError
    ? ExclamationOctagon
    : isWarning
      ? ExclamationTriangle
      : isSuccess
        ? Check2Circle
        : InfoSquare;

  const history = useHistory();

  const onButtonClick = () => {
    if (buttonRoute) {
      history.push(buttonRoute);
    }
  };

  return (
    <div
      className={cx(
        styles.root,
        isError && styles.error,
        isWarning && styles.warning,
        isSuccess && styles.success,
        className,
      )}
    >
      <div className={styles.inner}>
        <div className={styles.icon}>
          <Icon />
        </div>
        <div className={styles.message}>
          <div>{message}</div>
          {buttonLabel && buttonRoute && (
            <Button
              size={ButtonSizeEnum.XS}
              variant={
                isError || isSuccess || isWarning
                  ? ButtonVariantEnum.PRIMARY
                  : ButtonVariantEnum.OUTLINED
              }
              onClick={onButtonClick}
            >
              {buttonLabel}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
