import React from 'react';
import toast from 'react-hot-toast';
import { useMixer } from '../../MixerContext';
import ImagesSets from '../Fields/Image/ImagesSets';
import useFileUpload from '../../../../../hooks/useFileUpload';
import BannerElementType from '../../../../../shared/enums/bannerElementType';
import VariationStatusEnum from '../../../../../shared/enums/variationStatus';

const ImageElementOptions = ({ id }) => {
  const {
    bannerSet,
    previewOptions,
    handlePreviewOption,
    elementVariants,
    handleAddElementVariant,
    handleRemoveElementVariant,
    handleUpdateElementVariant,
  } = useMixer();
  const { uploadFile } = useFileUpload();

  const baseSet = bannerSet.banners[0].elements?.find(
    (el) => el._id === id,
  )?.variations;

  const preparedBaseSet = Object.entries(baseSet).reduce(
    (acc, [resolution, data]) => {
      acc[resolution] = {
        src: data.options?.src || '',
        status:
          data.status ||
          (data.options?.src
            ? VariationStatusEnum.ENABLED
            : VariationStatusEnum.DISABLED),
        id: data._id,
      };
      return acc;
    },
    {},
  );

  const handleAddNewSet = () => {
    handleAddElementVariant();
  };

  const handlePreview = (variantId) => {
    const isActive = previewOptions.elements?.[id]?.variantId === variantId;

    if (isActive) {
      handlePreviewOption({ name: 'elementImages', value: { elementId: id } });
      return;
    }

    handlePreviewOption({
      name: 'elementImages',
      value: {
        elementId: id,
        variantId,
      },
    });
  };

  const handleRemove = (setId, variants) => {
    const isActive = previewOptions.elementImages === variants;
    if (isActive) {
      handlePreviewOption({ name: 'elementImages', value: null });
    }
    handleRemoveElementVariant(setId);
  };

  const handleImageUpload = async (
    files,
    { resolution, setId, isActivePreviewSet },
  ) => {
    toast.promise(
      (async () => {
        const uploadedFiles = await uploadFile(files[0]);
        handleUpdateElementVariant({
          variantId: setId,
          resolution,
          updates: {
            src: uploadedFiles[0].path,
            status: VariationStatusEnum.ENABLED,
          },
        });

        if (isActivePreviewSet) {
          handlePreviewOption({
            name: 'elementImages',
            value: {
              elementId: id,
              variantId: setId,
            },
          });
        }
      })(),
      {
        loading: 'Uploading image...',
        success: 'Image uploaded successfully',
        error: 'Failed to upload image',
      },
    );
  };

  const handleImageRemove = ({ resolution, setId, isActivePreviewSet }) => {
    handleUpdateElementVariant({
      variantId: setId,
      resolution,
      updates: {
        src: '',
      },
    });

    if (isActivePreviewSet) {
      handlePreviewOption({
        name: 'elementImages',
        value: {
          elementId: id,
          variantId: setId,
        },
      });
    }
  };

  const handleImageDisable = ({
    resolution,
    setId,
    isDisabled,
    isActivePreviewSet,
  }) => {
    handleUpdateElementVariant({
      variantId: setId,
      resolution,
      updates: {
        status: isDisabled
          ? VariationStatusEnum.ENABLED
          : VariationStatusEnum.DISABLED,
      },
    });

    if (isActivePreviewSet) {
      handlePreviewOption({
        name: 'elementImages',
        value: {
          elementId: id,
          variantId: setId,
        },
      });
    }
  };

  const handleCopyMainToAll = (variantId) => {
    const mainResolution =
      bannerSet.resolutions[0].width + 'x' + bannerSet.resolutions[0].height;

    elementVariants
      .filter((v) => v.baseId === id && v.id === variantId)
      .forEach((variant) => {
        const mainSettings = variant.variations[mainResolution];
        bannerSet.resolutions.forEach((resolution) => {
          const resolutionKey = `${resolution.width}x${resolution.height}`;
          if (resolutionKey !== mainResolution) {
            if (
              variant.variations[resolutionKey].status ===
                VariationStatusEnum.DISABLED ||
              variant.variations[resolutionKey].status ===
                VariationStatusEnum.FORCE_DISABLED
            ) {
              return;
            }
            handleUpdateElementVariant({
              variantId: variant.id,
              resolution: resolutionKey,
              updates: {
                src: mainSettings?.src || '',
                status: mainSettings?.status || VariationStatusEnum.ENABLED,
              },
            });
          }
        });
      });
  };

  const handleCopyFromMain = (variantId, targetResolution) => {
    const mainResolution =
      bannerSet.resolutions[0].width + 'x' + bannerSet.resolutions[0].height;
    const mainVariation = baseSet[mainResolution];

    handleUpdateElementVariant({
      variantId: id,
      resolution: targetResolution,
      updates: {
        src: mainVariation.options?.src || '',
        status: mainVariation.status || VariationStatusEnum.ENABLED,
      },
    });

    elementVariants
      .filter((v) => v.baseId === id && v.id === variantId)
      .forEach((variant) => {
        const mainSettings = variant.variations[mainResolution];
        handleUpdateElementVariant({
          variantId: variant.id,
          resolution: targetResolution,
          updates: {
            src: mainSettings?.src || '',
            status: mainSettings?.status || VariationStatusEnum.ENABLED,
          },
        });
      });
  };

  return (
    <ImagesSets
      baseSet={preparedBaseSet}
      customVariants={elementVariants
        .filter((v) => v.type === BannerElementType.IMAGE)
        .filter((v) => v.baseId === id)}
      onAddNewSet={handleAddNewSet}
      onRemoveSet={handleRemove}
      onPreviewClick={handlePreview}
      activeSet={previewOptions.elements?.[id]?.variantId}
      onImageUpload={handleImageUpload}
      onImageRemove={handleImageRemove}
      onImageDisable={handleImageDisable}
      onCopyMainToAll={handleCopyMainToAll}
      onCopyFromMain={handleCopyFromMain}
    />
  );
};

export default ImageElementOptions;
