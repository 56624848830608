import { useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import fetchInstance from '../../../utils/fetchInstance';
import BannerSetStatusEnum from '../../../shared/enums/bannerSetStatus';

const useMixing = (id) => {
  const [bannerSet, setBannerSet] = useState(null);
  const [stats, setStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  useEffect(() => {
    let intervalId = null;
    let isRequestPending = false;

    const fetchBannerSet = async () => {
      try {
        setIsLoading(true);

        const response = await fetchInstance(
          `/getBannerSet/${id}?aggregate=true`,
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch banner set');
        }

        if (data.status === BannerSetStatusEnum.GALLERY) {
          clearInterval(intervalId);
          history.replace(`/gallery/${id}`);
          return;
        }

        if (
          data.status !== BannerSetStatusEnum.MIXING &&
          data.status !== BannerSetStatusEnum.MIXING_ERROR
        ) {
          throw new Error('Banner set is not in mixing mode');
        }

        setBannerSet(data);

        if (data.error || data.statusError) {
          setError(data.error || data.statusError);
          return;
        }
      } catch (error) {
        toast.error('Error fetching mixer data. Please try again.');
        console.error('Error fetching mixer:', error);
        setError('A apărut o eroare în timpul procesării.');
        clearInterval(intervalId);
      } finally {
        setIsLoading(false);
      }
    };

    const checkStatus = async () => {
      if (isRequestPending) return;

      try {
        isRequestPending = true;
        const response = await fetchInstance(`/checkBannerSetStatus/${id}`);
        const data = await response.json();

        if (data.status === BannerSetStatusEnum.GALLERY) {
          clearInterval(intervalId);
          history.replace(`/gallery/${id}`);
          return;
        }

        if (
          data.status === BannerSetStatusEnum.MIXING ||
          data.status === BannerSetStatusEnum.MIXING_ERROR
        ) {
          setStats({
            totalBanners: data.totalBanners,
            mixedBanners: data.mixedBanners,
          });
        }

        if (data.error || data.statusError) {
          setError(data.error || data.statusError);
          clearInterval(intervalId);
          return;
        }
      } catch (err) {
        setError('A apărut o eroare în timpul procesării.');
        clearInterval(intervalId);
      } finally {
        isRequestPending = false;
      }
    };

    intervalId = setInterval(checkStatus, 1000);

    fetchBannerSet();

    return () => {
      clearInterval(intervalId);
    };
  }, [id, history]);

  const progress = useMemo(() => {
    if (!stats?.totalBanners || !bannerSet?.resolutions?.length) {
      return 0;
    }
    let calculatedProgress = Math.floor(
      ((stats.mixedBanners * bannerSet?.resolutions?.length) /
        (stats.totalBanners * bannerSet?.resolutions?.length)) *
        100,
    );
    if (calculatedProgress < 0 || calculatedProgress === Infinity) {
      calculatedProgress = 0;
    }
    return calculatedProgress;
  }, [stats, bannerSet?.resolutions?.length]);

  const calculateTimeRemaining = () => {
    if (!stats || !bannerSet?.resolutions) return null;

    const remainingBanners = stats.totalBanners - stats.mixedBanners;
    if (remainingBanners <= 0) return null;

    const secondsPerBanner = 2 * bannerSet.resolutions.length;
    const totalSecondsRemaining = remainingBanners * secondsPerBanner;

    const hours = Math.floor(totalSecondsRemaining / 3600);
    const minutes = Math.ceil((totalSecondsRemaining % 3600) / 60);

    if (hours > 0) {
      return `${hours} ${hours === 1 ? 'hour' : 'hours'} and ${minutes} ${
        minutes === 1 ? 'minute' : 'minutes'
      }`;
    }
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
  };

  const timeRemaining = calculateTimeRemaining();

  return {
    bannerSet,
    stats,
    isLoading,
    progress,
    error,
    timeRemaining,
  };
};

export default useMixing;
