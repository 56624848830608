import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMixer } from '../../MixerContext';
import config from '../../../../../config';
import { useFonts } from '../../../../../context/FontsContext';
import VariationStatusEnum from '../../../../../shared/enums/variationStatus';
import styles from './OverviewBanners.module.css';
import generateBannerHTML from './generateBannerHtml';

const OverviewBanners = () => {
  const containerRef = useRef(null);
  const {
    bannerSet,
    customBackgroundVariants,
    elementVariants,
    rootOptions,
    selectedResolution,
  } = useMixer();
  const { fonts } = useFonts();

  const [width, height] = selectedResolution.split('x').map(Number);
  const [scale, setScale] = useState(1);
  const [scaledHeight, setScaledHeight] = useState(height);

  const combinations = useMemo(() => {
    if (!bannerSet?.banners?.[0]) return [];

    const allResolutions = bannerSet.resolutions.map(
      (res) => `${res.width}x${res.height}`,
    );

    const backgrounds = allResolutions.reduce((acc, resolution) => {
      acc[resolution] = [
        bannerSet.banners[0].backgrounds[resolution],
        ...customBackgroundVariants.map((v) => v.variations[resolution]),
      ];
      return acc;
    }, {});

    const elementsByType = bannerSet.banners[0].elements.reduce(
      (acc, baseElement) => {
        const variations = allResolutions.reduce((resAcc, resolution) => {
          const baseVariation = {
            ...baseElement.variations[resolution],
            frame: baseElement.variations[resolution]?.frame,
            isVisible:
              baseElement.variations[resolution]?.status ===
              VariationStatusEnum.ENABLED,
          };

          const variants = elementVariants
            .filter((v) => v.baseId === baseElement._id)
            .map((v) => ({
              ...v.variations[resolution],
              frame: baseElement.variations[resolution]?.frame,
              isVisible:
                v.variations[resolution]?.status ===
                VariationStatusEnum.ENABLED,
            }));

          resAcc[resolution] = [baseVariation, ...variants];
          return resAcc;
        }, {});

        acc[baseElement._id] = variations;
        return acc;
      },
      {},
    );

    const rootCombinations = [];

    const hasValidRootOptions = Object.entries(rootOptions)
      .filter(([key]) => !['id', '_id'].includes(key))
      .some(([, values]) => Array.isArray(values) && values.length > 0);

    if (hasValidRootOptions) {
      Object.entries(rootOptions)
        .filter(([key]) => !['id', '_id'].includes(key))
        .forEach(([key, values]) => {
          if (!Array.isArray(values)) return;

          if (['borderStyle', 'borderColor', 'borderThickness'].includes(key)) {
            if (!rootCombinations.borderGroup) {
              rootCombinations.borderGroup = {
                borderStyle: rootOptions.borderStyle || [],
                borderColor: rootOptions.borderColor || [],
                borderThickness: rootOptions.borderThickness || [],
              };
            }
          } else if (values.length > 0) {
            rootCombinations.push(...values.map((value) => ({ [key]: value })));
          }
        });
    }

    if (rootCombinations.length === 0 && !rootCombinations.borderGroup) {
      rootCombinations.push({});
    }

    if (rootCombinations.borderGroup) {
      const { borderStyle, borderColor, borderThickness } =
        rootCombinations.borderGroup;

      if (borderStyle.length && borderColor.length && borderThickness.length) {
        const borderCombinations = borderStyle.flatMap((style) =>
          borderColor.flatMap((color) =>
            borderThickness.map((thickness) => ({
              borderStyle: style,
              borderColor: color,
              borderThickness: thickness,
            })),
          ),
        );

        const otherCombinations = rootCombinations.filter(
          (opt) => !opt.borderGroup,
        );

        const finalRootCombinations =
          otherCombinations.length > 0
            ? otherCombinations.flatMap((baseOpt) =>
                borderCombinations.map((borderOpt) => ({
                  ...baseOpt,
                  ...borderOpt,
                })),
              )
            : borderCombinations;

        rootCombinations.length = 0;
        rootCombinations.push(...finalRootCombinations);
      }

      delete rootCombinations.borderGroup;
    }

    const allCombinations = [];

    const generateCombinations = (current, elements, depth = 0) => {
      if (depth === Object.keys(elements).length) {
        allCombinations.push(current);
        return;
      }

      const elementId = Object.keys(elements)[depth];
      const elementVariations = elements[elementId];

      const firstResolution = allResolutions[0];
      const baseVariants = elementVariations[firstResolution] || [];

      baseVariants.forEach((baseVariant) => {
        const variantForAllResolutions = allResolutions.reduce(
          (acc, resolution) => {
            acc[resolution] =
              elementVariations[resolution][
                elementVariations[firstResolution].indexOf(baseVariant)
              ];
            return acc;
          },
          {},
        );

        generateCombinations(
          {
            ...current,
            elements: {
              ...current.elements,
              [elementId]: variantForAllResolutions,
            },
          },
          elements,
          depth + 1,
        );
      });
    };

    backgrounds[allResolutions[0]]?.forEach((bg) => {
      rootCombinations.forEach((rootOpt) => {
        const backgroundsByResolution = allResolutions.reduce(
          (acc, resolution) => {
            acc[resolution] =
              backgrounds[resolution][
                backgrounds[allResolutions[0]].indexOf(bg)
              ];
            return acc;
          },
          {},
        );

        generateCombinations(
          {
            background: backgroundsByResolution,
            rootOptions: rootOpt,
            elements: {},
          },
          elementsByType,
        );
      });
    });

    return allCombinations;
  }, [bannerSet, customBackgroundVariants, elementVariants, rootOptions]);

  useEffect(() => {
    if (!containerRef.current || !width) return;

    const calculateScale = () => {
      const availableWidth = containerRef.current.parentElement.offsetWidth;
      const maxHeight = config.bannerWizard.maxHeight;

      const widthScale = availableWidth / width;
      const heightScale = maxHeight / height;

      const newScale = Math.min(
        1,
        Math.max(0.1, Math.min(widthScale, heightScale)),
      );
      setScale(newScale);
      setScaledHeight(height * newScale);
    };

    calculateScale();

    const resizeObserver = new ResizeObserver(calculateScale);
    resizeObserver.observe(containerRef.current.parentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [width, height]);

  return (
    <div className={styles.root} ref={containerRef}>
      <div className={styles.grid}>
        {combinations.map((combination, index) => {
          return (
            <div
              key={index}
              className={styles.previewWrapper}
              style={{ height: `${scaledHeight}px` }}
            >
              <iframe
                className={styles.preview}
                srcDoc={generateBannerHTML(
                  {
                    background: combination.background[selectedResolution],
                    rootOptions: combination.rootOptions,
                    elements: Object.fromEntries(
                      Object.entries(combination.elements).map(
                        ([id, variants]) => [id, variants[selectedResolution]],
                      ),
                    ),
                  },
                  selectedResolution,
                  fonts,
                )}
                width={width}
                height={height}
                style={{
                  width: `${width}px`,
                  height: `${height}px`,
                  transform: `scale(${scale})`,
                  transformOrigin: 'center center',
                }}
                frameBorder="0"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OverviewBanners;
