import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import galleryStyles from '../Gallery.module.scss';
import Delimiter from '../../styles/Delimiter';
import Button from '../../FormComponents/Button';
import { ButtonVariantEnum } from '../../../shared/enums/buttonEnum';

import FlexContainer from '../../styles/FlexContainer';
import { useGalleryContext } from '../Gallery.context';

import fetchInstance from '../../../utils/fetchInstance';
import Input from '../../FormComponents/Input';
import NetworkEnum from '../../../shared/enums/networkEnum';
import { GroupManagementForm } from './GroupManagementForm/GroupManagementForm';
import styles from './GallerySidebar.module.scss';

export const GallerySidebar = () => {
  const history = useHistory();
  const { selectedBannersIds, bannerSet } = useGalleryContext();

  const [lpScript, setLpScript] = useState(null);

  const copyScript = () => {
    const copyText = document.getElementById('textareaScript');

    if (copyText) {
      copyText.select();
      copyText.setSelectionRange(0, 99999);
      navigator.clipboard.writeText(copyText.value).then();
    }
  };

  const [isGenerateLpScriptLoading, setIsGenerateLpScriptLoading] =
    useState(false);

  const handleGenerateLpScript = async (event) => {
    event.stopPropagation();

    if (isGenerateLpScriptLoading) {
      return;
    }

    setIsGenerateLpScriptLoading(true);

    try {
      const response = await fetchInstance('/generatecode', {
        method: 'post',
      });

      if (!response.ok) {
        toast.error(response.statusText);
        return;
      }

      const responseJson = await response.json();

      if (responseJson.success) {
        toast.success('LP script generated successfully');
        setLpScript(responseJson.code);
        return;
      }

      if (responseJson.error) toast.error(responseJson.message);
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsGenerateLpScriptLoading(false);
    }
  };

  const onPublickButtonClick = () => {
    let pushRoute = '';
    if (
      bannerSet?.platform === NetworkEnum.FACEBOOK ||
      bannerSet?.platform === NetworkEnum.INSTAGRAM
    ) {
      pushRoute = `/facebookCampaign/${bannerSet._id}`;
    }

    if (bannerSet?.platform === NetworkEnum.GOOGLE) {
      pushRoute = `/googleCampaign/${bannerSet._id}`;
    }

    history.push(pushRoute);
  };

  const publishButtonLabel =
    bannerSet?.platform === NetworkEnum.FACEBOOK ||
    bannerSet?.platform === NetworkEnum.INSTAGRAM
      ? 'Send to Meta'
      : 'Send to Google';

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Button
          variant={ButtonVariantEnum.OUTLINED}
          className={styles.sendToPlatformBtn}
          disabled={false}
          onClick={onPublickButtonClick}
        >
          {publishButtonLabel}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <FlexContainer className={galleryStyles.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FlexContainer direction="row" justifyContent="space-between">
                <FlexContainer direction="column" justifyContent="center">
                  <h4 className={galleryStyles.title}>Image Groups</h4>
                </FlexContainer>

                <div>Selected: {selectedBannersIds.length}</div>
              </FlexContainer>
            </Grid>
            <Grid item xs={12}>
              <Delimiter />
            </Grid>
            <Grid item xs={12}>
              <GroupManagementForm />
            </Grid>
          </Grid>
        </FlexContainer>
      </Grid>
      <Grid item xs={12}>
        <div className={galleryStyles.card}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h4 className={galleryStyles.title}>LP Integration</h4>
            </Grid>
            <Grid item xs={12}>
              <Delimiter />
            </Grid>

            <Grid item xs={12}>
              <FlexContainer direction="column">
                <div>
                  <Button
                    variant={ButtonVariantEnum.SECONDARY}
                    onClick={handleGenerateLpScript}
                    loading={isGenerateLpScriptLoading}
                  >
                    Get the script
                  </Button>
                </div>

                {lpScript !== null && (
                  <Input
                    isTextarea
                    value={`<script>${lpScript}</script>`}
                    id="lpScript"
                    onClick={copyScript}
                  />
                )}
              </FlexContainer>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant={ButtonVariantEnum.SECONDARY}
                onClick={() => {
                  history.push(`/generateScript/${bannerSet._id}`);
                }}
              >
                Set Personalization Rules
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
