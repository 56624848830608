import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { CloudUpload, X, Trash, Ban } from 'react-bootstrap-icons';
import cx from 'classnames';
import { nanoid } from 'nanoid';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../../shared/enums/buttonEnum';
import Button from '../../../../../Components/FormComponents/Button';
import VariationStatusEnum from '../../../../../shared/enums/variationStatus';
import fetchInstance from '../../../../../utils/fetchInstance';
import styles from './ModalBody.module.css';

const BulkUploadModalBody = ({
  toastId,
  allResolutions,
  handleAddVariant,
  disabledResolutions,
}) => {
  const [uploadedFiles, setUploadedFiles] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const abortControllerRef = React.useRef(null);

  const handleFileUpload = (resolution, files) => {
    const fileArray = Array.from(files).map((file) => ({
      file,
      id: nanoid(),
      name: file.name,
    }));

    setUploadedFiles((prev) => ({
      ...prev,
      [resolution]: [...(prev[resolution] || []), ...fileArray],
    }));
  };

  const removeFile = (resolution, index) => {
    setUploadedFiles((prev) => {
      const newFiles = prev[resolution].filter((_, i) => i !== index);
      const newState = { ...prev };
      if (newFiles.length === 0) {
        delete newState[resolution];
      } else {
        newState[resolution] = newFiles;
      }
      return newState;
    });
  };

  const uploadFile = async ({ file, id, name }) => {
    const formData = new FormData();
    formData.append('persistent', 'true');
    formData.append('multiple', 'false');
    formData.append('files', file);

    const response = await fetchInstance('/store', {
      method: 'POST',
      body: formData,
      signal: abortControllerRef.current?.signal,
    });

    const data = await response.json();
    if (!data.files?.[0]) throw new Error('No file in response');

    return {
      fileId: id,
      filename: name,
      path: data.files[0].path,
    };
  };

  const handleConfirm = async () => {
    const maxImages = Math.max(
      ...allResolutions.map((res) => uploadedFiles[res]?.length || 0),
    );

    if (maxImages === 0) {
      toast.error('Please upload at least one image');
      return;
    }

    try {
      setIsUploading(true);
      abortControllerRef.current = new AbortController();

      const allFiles = [
        ...new Map(
          Object.values(uploadedFiles)
            .flat()
            .map((fileObj) => [fileObj.id, fileObj]),
        ).values(),
      ];

      const uploadedFileData = await Promise.all(
        allFiles.map((fileObj) => uploadFile(fileObj)),
      );

      const uploadedFilesMap = new Map(
        uploadedFileData.map((file) => [file.fileId, file.path]),
      );

      for (let i = 0; i < maxImages; i++) {
        const variations = allResolutions.reduce((acc, resolution) => {
          const files = uploadedFiles[resolution] || [];
          const fileObj = files[i];

          acc[resolution] = {
            src: fileObj ? uploadedFilesMap.get(fileObj.id) || '' : '',
            status: fileObj
              ? VariationStatusEnum.ENABLED
              : VariationStatusEnum.DISABLED,
            id: nanoid(),
          };
          return acc;
        }, {});

        handleAddVariant({ variations, id: nanoid() });
      }

      toast.dismiss(toastId);
      toast.success(`Added ${maxImages} new variation sets`);
    } catch (error) {
      if (error.name === 'AbortError') {
        toast.error('Upload cancelled');
      } else {
        toast.error('Failed to upload images');
        console.error('Error handling bulk upload:', error);
      }
    } finally {
      setIsUploading(false);
      abortControllerRef.current = null;
    }
  };

  const handleClearResolution = (resolution) => {
    setUploadedFiles((prev) => {
      const newState = { ...prev };
      delete newState[resolution];
      return newState;
    });
  };

  const handleCancel = () => {
    if (isUploading && abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    toast.dismiss(toastId);
  };

  return (
    <div className={styles.root}>
      <h3 className={styles.title}>Bulk upload</h3>
      <p className={styles.description}>
        Upload multiple images for each resolution. Images will be grouped by
        their position to create new variations. For example, all first images
        will create the first variation set, all second images will create the
        second variation set, and so on. Resolutions without images will be
        marked as disabled in their respective variations.
      </p>
      <div
        className={cx(
          styles.resolutionsContainer,
          isUploading && styles.uploading,
        )}
      >
        {allResolutions.map((res, index) => {
          const isDisabled = disabledResolutions.includes(res);
          return (
            <div
              key={`${res}-${index}`}
              className={cx(styles.resolution, isDisabled && styles.disabled)}
            >
              <div className={styles.resolutionName}>
                {res}
                {isDisabled && (
                  <div className={styles.disabledTag}>
                    <Ban /> disabled
                  </div>
                )}
              </div>
              <div className={styles.uploadedFiles}>
                {uploadedFiles[res]?.map((fileObj, fileIndex) => (
                  <div key={fileIndex} className={styles.uploadedFile}>
                    <img
                      src={URL.createObjectURL(fileObj.file)}
                      alt={`Preview ${fileIndex}`}
                      className={styles.preview}
                    />
                    <Button
                      size={ButtonSizeEnum.XS}
                      variant={ButtonVariantEnum.TEXT}
                      onClick={() => removeFile(res, fileIndex)}
                      className={styles.removeButton}
                    >
                      <X />
                    </Button>
                  </div>
                ))}
              </div>
              <input
                type="file"
                multiple
                accept="image/*"
                onChange={(e) => handleFileUpload(res, e.target.files)}
                style={{ display: 'none' }}
                id={`file-input-${res}`}
              />
              <div className={styles.resolutionActions}>
                <Button
                  size={ButtonSizeEnum.S}
                  variant={ButtonVariantEnum.TEXT}
                  leftIcon={<CloudUpload />}
                  onClick={() =>
                    document.getElementById(`file-input-${res}`).click()
                  }
                >
                  Upload images
                </Button>
                {uploadedFiles[res]?.length > 0 && (
                  <Button
                    size={ButtonSizeEnum.S}
                    variant={ButtonVariantEnum.TEXT}
                    leftIcon={<Trash />}
                    onClick={() => handleClearResolution(res)}
                    className={styles.clearButton}
                  >
                    Clear all
                  </Button>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.actions}>
        <Button
          size={ButtonSizeEnum.S}
          variant={ButtonVariantEnum.PRIMARY}
          onClick={handleConfirm}
          loading={isUploading}
        >
          Add variations
        </Button>
        <Button
          size={ButtonSizeEnum.S}
          variant={ButtonVariantEnum.SECONDARY}
          onClick={handleCancel}
          disabled={false}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default BulkUploadModalBody;
