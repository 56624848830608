import React, { useState, useCallback } from 'react';
import { ArrowRightShort } from 'react-bootstrap-icons';
import { toast } from 'react-hot-toast';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';

import DefaultLayout from '../../Components/DefaultLayout';
import TopBar from '../TopBar/TopBar';
import MessageBox from '../MessageBox/MessageBox';
import Button from '../../Components/FormComponents/Button';
import DestinationCard from './cards/DestinationCard';
import BannersCard from './cards/BannersCard';
import AdDetailsCard from './cards/Google/AdDetailsCard';
import URLBuilderCard from './cards/URLBuilderCard';
import { useGoogleAds } from './hooks/google/useGoogleAds';

import styles from './styles/GoogleCampaignPage.module.scss';
import GoogleAdType from './cards/GoogleAdType';
import AD_TYPE from './constants/adTypeEnum';

const compareUrls = (url1, url2) => {
  if (!url1 || !url2) {
    return true;
  }

  const ensureUrlHasScheme = (url) => {
    if (!/^https?:\/\//i.test(url)) {
      return `https://${url}`;
    }
    return url;
  };

  try {
    const websiteDomain = new URL(ensureUrlHasScheme(url1)).hostname.replace(
      /^www\./,
      '',
    );
    const displayDomain = new URL(ensureUrlHasScheme(url2)).hostname.replace(
      /^www\./,
      '',
    );
    return websiteDomain === displayDomain;
  } catch (error) {
    return false;
  }
};

const validationSchema = Yup.object().shape({
  destination: Yup.object().shape({
    account: Yup.object().nullable().required('Ad Account is required'),
    campaign: Yup.object().nullable().required('Campaign is required'),
    adGroup: Yup.object().nullable().required('Ad Group is required'),
  }),
  banners: Yup.object().shape({
    group: Yup.object().nullable().required('Group is required'),
    resolutions: Yup.array().min(1, 'At least one resolution is required'),
  }),
  urlBuilder: Yup.object().shape({
    websiteUrl: Yup.string()
      .url('Must be a valid URL')
      .required('Website URL is required'),
    campaignSource: Yup.string().required('Campaign Source is required'),
    campaignMedium: Yup.string().required('Campaign Medium is required'),
    campaignName: Yup.string().required('Campaign Name is required'),
    campaignTerm: Yup.string().required('Campaign Term is required'),
    campaignContent: Yup.string(),
  }),
  adDetails: Yup.object().shape({
    namePrefix: Yup.string().required('Name Prefix is required'),
    displayUrl: Yup.string()
      .required('Display URL is required')
      .test(
        'is-same-domain',
        'Display URL must be the same domain as Website URL',
        function (displayUrl) {
          return compareUrls(
            this.options.context?.urlBuilder?.websiteUrl,
            displayUrl,
          );
        },
      ),
  }),
});

const GoogleAdsPage = () => {
  const [unlockedSteps, setUnlockedSteps] = useState([]);
  const [error, setError] = useState(null);

  const {
    bannerSet,
    handleSubmit,
    loading,
    handleStepComplete,
    loggedIn,
    initLoading,
    submitError,
    success,
  } = useGoogleAds({
    unlockedSteps,
    setUnlockedSteps,
  });

  const initialValues = {
    destination: {
      account: null,
      campaign: null,
      adGroup: null,
    },
    banners: {
      group: null,
      resolutions: [],
    },
    urlBuilder: {
      websiteUrl: '',
      campaignSource: '',
      campaignMedium: '',
      campaignName: '',
      campaignTerm: '',
      campaignContent: '',
    },
    adDetails: {
      namePrefix: '',
      displayUrl: '',
      finalUrl: '',
    },
    adType: AD_TYPE.CLASSIC,
  };

  const onFormSubmit = useCallback(
    async (values, { setSubmitting }) => {
      try {
        await handleSubmit(values);
      } finally {
        setSubmitting(false);
      }
    },
    [handleSubmit],
  );

  const handleFormSubmit = useCallback(
    async (values, { setSubmitting }) => {
      toast.promise(onFormSubmit(values, { setSubmitting }), {
        loading: 'Creating ad...',
        success: 'Ad created!',
        error: 'Could not create ad.',
      });
    },
    [onFormSubmit],
  );

  return (
    <DefaultLayout>
      <div className={styles.wrapper}>
        <TopBar title={bannerSet?.name} network={bannerSet?.platform} />
        {!initLoading && error && (
          <MessageBox
            message={error}
            isError
            buttonLabel="Connect"
            buttonRoute="/profile"
            className={styles.errorMessage}
          />
        )}
        {!initLoading && !loggedIn && (
          <MessageBox
            message="Please connect your Google account to continue."
            isWarning
            buttonLabel="Connect"
            buttonRoute="/profile"
          />
        )}
        {!initLoading && loggedIn && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnChange={true}
            validateOnBlur={true}
            onSubmit={handleFormSubmit}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className={styles.cardsGrid}>
                    <DestinationCard
                      disabled={!unlockedSteps.includes(1)}
                      platform="google"
                      onComplete={(data) => {
                        setFieldValue('destination', data);
                        handleStepComplete('destination', values);
                      }}
                      setError={setError}
                    />
                    <BannersCard
                      disabled={!unlockedSteps.includes(2)}
                      platform="google"
                      onComplete={async (data) => {
                        await setFieldValue('banners', data);
                        const updatedValues = { ...values, banners: data };
                        handleStepComplete('banners', updatedValues);
                      }}
                      bannerSet={bannerSet}
                    />
                  </div>
                  <div className={styles.card}>
                    <URLBuilderCard
                      disabled={!unlockedSteps.includes(3)}
                      onComplete={(urlBuilderData) => {
                        Promise.all([
                          setFieldValue('urlBuilder', urlBuilderData),
                        ]).then(() => {
                          handleStepComplete('urlBuilder', {
                            ...values,
                            urlBuilder: urlBuilderData,
                            adDetails: {
                              ...values.adDetails,
                            },
                          });
                        });
                      }}
                    />
                  </div>
                  <div className={styles.card}>
                    <AdDetailsCard
                      platform="google"
                      disabled={!unlockedSteps.includes(3)}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <GoogleAdType disabled={!unlockedSteps.includes(3)} />

                  {submitError && (
                    <MessageBox
                      message={submitError.message || submitError}
                      isError
                      className={styles.messageBox}
                    />
                  )}

                  {success && (
                    <MessageBox
                      message="Datele au fost incarcate cu succes in Google Ads!"
                      isSuccess
                      className={styles.messageBox}
                    />
                  )}
                  <div className={styles.submitFooter}>
                    <Button
                      type="submit"
                      rightIcon={<ArrowRightShort />}
                      loading={loading}
                    >
                      {loading ? 'Sending to Google...' : 'Send to Google'}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
      </div>
    </DefaultLayout>
  );
};

export default GoogleAdsPage;
