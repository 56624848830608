import React, { useState } from 'react';

import Button from '../../../../../Components/FormComponents/Button';
import { ButtonVariantEnum } from '../../../../../shared/enums/buttonEnum';
import { Dispatcher, Events } from '../../../../../Components/Events';
import VariationStatusEnum from '../../../../../shared/enums/variationStatus';
import styles from './BannerImageOptions.module.css';
import ResolutionItem from './ResolutionItem';

const BannerImageOptions = ({
  closeModal,
  resolutions,
  edit,
  variations,
  id,
  currentElementsLength,
}) => {
  const [uploads, setUploads] = useState(() => {
    if (edit && variations) {
      const initialUploads = {};
      Object.entries(variations).forEach(([resolution, data]) => {
        if (data.status === VariationStatusEnum.ENABLED && data.options.src) {
          initialUploads[resolution] = {
            file: {
              path: data.options.src,
              dimensions: data.options.dimensions || {
                width: data.frame.width,
                height: data.frame.height,
              },
            },
            class: data.options.class || '',
            style: data.options.style || '',
          };
        }
      });
      return initialUploads;
    }
    return {};
  });

  const [error, setError] = useState(null);
  const [errorResolutions, setErrorResolutions] = useState([]);
  const [disabledResolutions, setDisabledResolutions] = useState(() => {
    if (edit && variations) {
      return Object.entries(variations)
        .filter(([, data]) => data.status === VariationStatusEnum.DISABLED)
        .map(([resolution]) => resolution);
    }
    return [];
  });
  const [mainResolution] = useState(resolutions[0]);

  const handleFileChange = (resolution) => async (uploader) => {
    const file = uploader.uploads[0];

    const img = new Image();
    img.src = file.path;

    await new Promise((resolve) => {
      img.onload = () => {
        const originalWidth = img.width;
        const originalHeight = img.height;
        const [maxWidth, maxHeight] = resolution.split('x').map(Number);
        let finalWidth = originalWidth;
        let finalHeight = originalHeight;
        if (originalWidth > maxWidth || originalHeight > maxHeight) {
          const ratioWidth = maxWidth / originalWidth;
          const ratioHeight = maxHeight / originalHeight;
          const ratio = Math.min(ratioWidth, ratioHeight);

          finalWidth = Math.floor(originalWidth * ratio);
          finalHeight = Math.floor(originalHeight * ratio);
        }

        setUploads((prev) => ({
          ...prev,
          [resolution]: {
            file: {
              ...file,
              dimensions: {
                width: finalWidth,
                height: finalHeight,
                originalWidth,
                originalHeight,
              },
            },
            uploader,
          },
        }));

        resolve();
      };
    });

    setErrorResolutions((prev) => {
      const updatedErrorResolutions = prev.filter((res) => res !== resolution);
      if (updatedErrorResolutions.length > 0) {
        setError(
          `Please upload images for all resolutions. Missing: ${updatedErrorResolutions.join(
            ', ',
          )}`,
        );
      } else {
        setError(null);
      }
      return updatedErrorResolutions;
    });
  };

  const handleDisable = (resolution) => {
    setDisabledResolutions((prev) => {
      const isCurrentlyDisabled = prev.includes(resolution);

      if (isCurrentlyDisabled) {
        return prev.filter((res) => res !== resolution);
      } else {
        if (uploads[resolution]) {
          setUploads((prevUploads) => {
            // eslint-disable-next-line no-unused-vars
            const { [resolution]: removed, ...rest } = prevUploads;
            return rest;
          });
        }
        setErrorResolutions((current) =>
          current.filter((res) => res !== resolution),
        );
        return [...prev, resolution];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingResolutions = resolutions
      .filter((res) => !disabledResolutions.includes(res))
      .filter((res) => !uploads[res]);

    if (missingResolutions.length > 0) {
      setErrorResolutions(missingResolutions);
      setError(
        `Please upload images for all enabled resolutions. Missing: ${missingResolutions.join(
          ', ',
        )}`,
      );
      return;
    }

    const variations = {};

    resolutions.forEach((resolution) => {
      if (disabledResolutions.includes(resolution)) {
        variations[resolution] = {
          status: VariationStatusEnum.DISABLED,
          frame: {
            'z-index':
              variations[resolution]?.frame?.['z-index'] ||
              currentElementsLength,
          },
        };
      } else {
        if (edit) {
          const existingVariation = variations[resolution] || {};
          const { file, class: className, style } = uploads[resolution];

          variations[resolution] = {
            ...existingVariation,
            options: {
              ...existingVariation?.options,
              src: file.path,
              dimensions: file.dimensions,
              style: style || '',
              class: className || '',
            },
            frame: {
              ...existingVariation?.frame,
              width:
                existingVariation?.frame?.width || `${file.dimensions.width}px`,
              height:
                existingVariation?.frame?.height ||
                `${file.dimensions.height}px`,
              left:
                existingVariation?.frame?.left ||
                `${(resolution.split('x')[0] - file.dimensions.width) / 2}px`,
              top:
                existingVariation?.frame?.top ||
                `${(resolution.split('x')[1] - file.dimensions.height) / 2}px`,
              'z-index':
                existingVariation?.frame?.['z-index'] || currentElementsLength,
            },
          };
        } else {
          const [containerWidth, containerHeight] = resolution
            .split('x')
            .map(Number);
          const { width, height } = uploads[resolution].file.dimensions;
          const left = (containerWidth - width) / 2;
          const top = (containerHeight - height) / 2;

          variations[resolution] = {
            status: VariationStatusEnum.ENABLED,
            options: {
              src: uploads[resolution].file.path,
              dimensions: uploads[resolution].file.dimensions,
              style: uploads[resolution]?.style || '',
              class: uploads[resolution]?.class || '',
            },
            frame: {
              width: `${width}px`,
              height: `${height}px`,
              left: `${left}px`,
              top: `${top}px`,
              'z-index': currentElementsLength,
            },
          };
        }
      }
    });

    Dispatcher.dispatch({
      event: edit ? Events.UPDATE_BANNER_ELEMENT : Events.ADD_BANNER_ELEMENT,
      payload: {
        id: edit ? id : undefined,
        type: 'BannerImageItem',
        variations,
      },
    });

    closeModal();
  };

  const calculateDimensions = (
    originalWidth,
    originalHeight,
    maxWidth,
    maxHeight,
  ) => {
    let finalWidth = originalWidth;
    let finalHeight = originalHeight;

    if (originalWidth > maxWidth || originalHeight > maxHeight) {
      const widthRatio = maxWidth / originalWidth;
      const heightRatio = maxHeight / originalHeight;
      const ratio = Math.min(widthRatio, heightRatio);

      finalWidth = Math.floor(originalWidth * ratio);
      finalHeight = Math.floor(originalHeight * ratio);
    }

    return { width: finalWidth, height: finalHeight };
  };

  const handleCopyToAll = () => {
    if (!uploads[mainResolution]) return;

    const mainUpload = uploads[mainResolution];
    const updatedUploads = {};

    resolutions.forEach((resolution) => {
      if (!disabledResolutions.includes(resolution)) {
        const [maxWidth, maxHeight] = resolution.split('x').map(Number);
        const { originalWidth, originalHeight } = mainUpload.file.dimensions;

        // Calculăm noile dimensiuni pentru această rezoluție
        const { width, height } = calculateDimensions(
          originalWidth,
          originalHeight,
          maxWidth,
          maxHeight,
        );

        updatedUploads[resolution] = {
          ...uploads[resolution],
          status: VariationStatusEnum.ENABLED,
          file: {
            ...mainUpload.file,
            dimensions: {
              width,
              height,
              originalWidth,
              originalHeight,
            },
          },
          uploader: mainUpload.uploader,
          class: mainUpload.class || '',
          style: mainUpload.style || '',
        };
      }
    });

    setUploads((prev) => ({
      ...prev,
      ...updatedUploads,
    }));

    setErrorResolutions((prev) =>
      prev.filter((res) => disabledResolutions.includes(res)),
    );
    setError(null);
  };

  const handleCopyFromMain = (targetResolution) => {
    if (!uploads[mainResolution]) return;

    const mainUpload = uploads[mainResolution];
    const [maxWidth, maxHeight] = targetResolution.split('x').map(Number);
    const { originalWidth, originalHeight } = mainUpload.file.dimensions;

    // Calculăm dimensiunile pentru rezoluția țintă
    const { width, height } = calculateDimensions(
      originalWidth,
      originalHeight,
      maxWidth,
      maxHeight,
    );

    setUploads((prev) => ({
      ...prev,
      [targetResolution]: {
        ...prev[targetResolution],
        status: VariationStatusEnum.ENABLED,
        file: {
          ...mainUpload.file,
          dimensions: {
            width,
            height,
            originalWidth,
            originalHeight,
          },
        },
        uploader: mainUpload.uploader,
        class: mainUpload.class || '',
        style: mainUpload.style || '',
      },
    }));

    setErrorResolutions((prev) =>
      prev.filter((res) => res !== targetResolution),
    );
    if (errorResolutions.length <= 1) {
      setError(null);
    }
  };

  const handleClassChange = (resolution) => (e) => {
    e.persist();
    const value = e.target.value || '';
    setUploads((prev) => ({
      ...prev,
      [resolution]: {
        ...prev[resolution],
        class: value,
      },
    }));
  };

  const handleStyleChange = (resolution) => (e) => {
    e.persist();
    const value = e.target.value || '';
    setUploads((prev) => ({
      ...prev,
      [resolution]: {
        ...prev[resolution],
        style: value,
      },
    }));
  };

  return (
    <div className={styles.root}>
      <form onSubmit={handleSubmit}>
        <div className={styles.uploadsGrid}>
          {resolutions.map((resolution) => {
            const hasUpload = uploads[resolution]?.file;
            const hasError = errorResolutions.includes(resolution);
            const isDisabled = disabledResolutions.includes(resolution);
            const isMainResolution = resolution === mainResolution;
            const showCopyButtons = resolutions.length > 1;

            return (
              <ResolutionItem
                key={resolution}
                resolution={resolution}
                hasUpload={hasUpload}
                hasError={hasError}
                isDisabled={isDisabled}
                isMainResolution={isMainResolution}
                showCopyButtons={showCopyButtons}
                handleDisable={handleDisable}
                handleCopyToAll={handleCopyToAll}
                handleCopyFromMain={handleCopyFromMain}
                handleFileChange={handleFileChange}
                currentOptions={{
                  file: uploads[resolution]?.file || {},
                  class: uploads[resolution]?.class || '',
                  style: uploads[resolution]?.style || '',
                }}
                onClassChange={handleClassChange}
                onStyleChange={handleStyleChange}
                isDisabledResolution={isDisabled}
              />
            );
          })}
        </div>

        {error && <div className={styles.error}>{error}</div>}

        <div className={styles.actions}>
          <Button
            type="submit"
            variant={ButtonVariantEnum.PRIMARY}
            className={styles.actionButton}
          >
            {edit ? 'Update' : 'Add'}
          </Button>
          <Button
            type="button"
            variant={ButtonVariantEnum.SECONDARY}
            onClick={closeModal}
            className={styles.actionButton}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BannerImageOptions;
