import React, { useEffect, useMemo, useState } from 'react';
import { CloudDownload } from 'react-bootstrap-icons';
import Grid from '@material-ui/core/Grid';
import Select from '../../../FormComponents/Select';
import Button from '../../../FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../shared/enums/buttonEnum';
import { useGalleryContext } from '../../Gallery.context';
import FlexContainer from '../../../styles/FlexContainer';
import { ManageGroupsModal } from './ManageGroupsModal';
import { useGroupManagementFormApi } from './useGroupManagementFormApi';

export const GroupManagementForm = () => {
  const [manageGroupsModalOpen, setManageGroupsModalOpen] = useState(false);

  const { bannerGroups, refetchBannerGroups } = useGalleryContext();

  const {
    handleAddSelected,
    handleRemoveSelected,
    handleDownloadSelected,
    handleDownloadAll,
  } = useGroupManagementFormApi();

  const [selectedGroup, setSelectedGroup] = useState(null);

  const bannerGroupsOptions = useMemo(() => {
    return bannerGroups.map((group) => ({
      label: group.name,
      value: group._id,
      rawGroup: group,
    }));
  }, [bannerGroups]);

  useEffect(() => {
    if (!selectedGroup) {
      setSelectedGroup(bannerGroupsOptions[0]);
    }
  }, [bannerGroupsOptions]);

  return (
    <FlexContainer direction="column">
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Select
            name="groups"
            value={selectedGroup?.value || null}
            options={bannerGroupsOptions}
            onChange={(event) => {
              const newValue = event.target.value;

              const newGroup = bannerGroupsOptions.find(
                (group) => group.value === newValue,
              );

              setSelectedGroup(newGroup);
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <Button
            stretch
            variant={ButtonVariantEnum.OUTLINED}
            size={ButtonSizeEnum.S}
            onClick={(e) => handleAddSelected(e, selectedGroup)}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            stretch
            variant={ButtonVariantEnum.OUTLINED}
            size={ButtonSizeEnum.S}
            onClick={(e) => handleRemoveSelected(e, selectedGroup)}
          >
            Remove
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Button
            variant={ButtonVariantEnum.PRIMARY}
            onClick={() => setManageGroupsModalOpen(true)}
            size={ButtonSizeEnum.S}
          >
            Manage Groups
          </Button>
        </Grid>
        <Grid
          item
          xs={7}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <Button
            stretch
            variant={ButtonVariantEnum.SECONDARY}
            onClick={handleDownloadAll}
            size={ButtonSizeEnum.S}
            leftIcon={<CloudDownload />}
          >
            All
          </Button>
          <Button
            stretch
            variant={ButtonVariantEnum.SECONDARY}
            onClick={handleDownloadSelected}
            size={ButtonSizeEnum.S}
            leftIcon={<CloudDownload />}
          >
            Selected
          </Button>
        </Grid>
      </Grid>

      {manageGroupsModalOpen && (
        <ManageGroupsModal
          handleCloseModal={(updated) => {
            if (updated) {
              refetchBannerGroups();
            }

            setManageGroupsModalOpen(false);
          }}
        />
      )}
    </FlexContainer>
  );
};
