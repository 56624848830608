import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import ArrowLeftOutlined from '@material-ui/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@material-ui/icons/ArrowRightOutlined';

function extractClassNameOrId(selector) {
  const classNameRegex = /\.([^.#\s]+)$/;
  const idRegex = /#([^.#\s]+)$/;

  const classMatch = classNameRegex.exec(selector);
  const idMatch = idRegex.exec(selector);

  if (classMatch) {
    return '.' + classMatch[1];
  } else if (idMatch) {
    return '#' + idMatch[1];
  }

  return null;
}

function StepContent({ stepIndex, formItems }) {
  try {
    const iframeDocument =
      document.getElementById('previewBannerIframe').contentDocument ||
      document.getElementById('previewBannerIframe').contentWindow.document;

    const highlightScripts = Array.from(
      iframeDocument.head.getElementsByTagName('script'),
    ).filter(
      (script) =>
        script.textContent && script.textContent.includes('highlightElement'),
    );
    highlightScripts.forEach((script) => script.remove());

    let selector = extractClassNameOrId(formItems[stepIndex].props.id);
    if (selector.includes('#')) {
      selector = '#el_' + selector.replace('#', '');
    }

    const elementInIframe = iframeDocument.querySelector(selector);
    const script = iframeDocument.getElementById('inspector-js');

    if (!script) {
      const script = iframeDocument.createElement('script');
      script.src = '/inspector.js';
      script.async = false;
      script.defer = true;
      script.id = 'inspector-js';
      iframeDocument.head.appendChild(script);
    }

    if (elementInIframe && script) {
      const script = iframeDocument.createElement('script');
      script.textContent = `highlightElement("${selector}");`;
      iframeDocument.head.appendChild(script);
    }
  } catch (e) {
    console.log('Error in StepContent', e);
  }

  return formItems[stepIndex];
}

class ScriptWizard extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  render() {
    const { activeStep } = this.state;
    const { formItems } = this.props;

    return (
      <div>
        <div className={'wizardButtons'}>
          <Button
            disabled={activeStep === 0}
            onClick={this.handleBack}
            style={{ width: 60, marginRight: 10 }}
          >
            <ArrowLeftOutlined fontSize="large" />
          </Button>
          <Button
            disabled={activeStep === formItems.length - 1}
            variant="contained"
            color="primary"
            onClick={this.handleNext}
            style={{ width: 60, marginRight: 10 }}
          >
            <ArrowRightOutlined fontSize="large" />
          </Button>
          <div>
            {activeStep + 1} of {formItems.length} elements
          </div>
        </div>
        <div>
          <StepContent stepIndex={activeStep} formItems={formItems} />
        </div>
      </div>
    );
  }
}

export default ScriptWizard;
