import React, { useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Trash3, PencilSquare, Copy, ColumnsGap } from 'react-bootstrap-icons';
import cx from 'classnames';
import { ReactComponent as ResolutionsIcon } from '../../images/resolutions.svg';
import formatDateWithPastWeek from '../../utils/formatDateWithPastWeek';
import FlexContainer from '../../Components/styles/FlexContainer';
import config from '../../config';
import SocialIcon from '../SocialIcon/SocialIcon';
import placeholderImage from '../../images/placeholder_banner_set.svg';
import { getBannerSetUrl } from '../../utils/handleBannerSetRedirect';
import styles from './BannerSetCard.module.scss';

const BannerSetCard = ({
  bannerSet,
  openBannerSetModal,
  handleDuplicate,
  openDeleteModal,
}) => {
  const history = useHistory();

  if (!bannerSet) return null;

  const {
    name = '',
    status,
    createdAt,
    resolutions = [],
    numberOfBannerMix,
    featuredImage,
    platform,
  } = bannerSet;

  const handleEdit = (event) => {
    event.stopPropagation();
    openBannerSetModal(bannerSet);
  };

  const route = useMemo(() => {
    const redirectUrl = getBannerSetUrl(bannerSet.status, bannerSet._id);
    if (redirectUrl) {
      return redirectUrl;
    }
    return `${config.appRoutes.bannerCreator}/${bannerSet._id}`;
  }, [bannerSet]);

  return (
    <div
      className={styles.root}
      onClick={(event) => {
        event.stopPropagation();
        history.push(route);
      }}
    >
      <FlexContainer gap="10px" className={styles.settings}>
        <button onClick={handleEdit} className={styles.settingsButton}>
          <PencilSquare width={12} height={12} />
        </button>
        <button
          onClick={(event) => {
            event.stopPropagation();
            handleDuplicate(bannerSet);
          }}
          className={styles.settingsButton}
        >
          <Copy width={12} height={12} />
        </button>
        <button
          onClick={(event) => {
            event.stopPropagation();
            openDeleteModal(bannerSet);
          }}
          className={styles.settingsButton}
        >
          <Trash3 width={12} height={12} />
        </button>
      </FlexContainer>

      <div className={styles.network}>
        <SocialIcon networks={[platform]} className={styles.networkIcon} />
      </div>
      <Link to={route}>
        <div className={styles.imageContainer}>
          {featuredImage && (
            <div
              className={styles.imageBlurrBackground}
              style={{
                backgroundImage: `url(${featuredImage})`,
              }}
            />
          )}
          <img
            src={featuredImage || placeholderImage}
            alt={name}
            className={cx(
              styles.bannerImage,
              !featuredImage && styles.placeholderImage,
            )}
          />
        </div>

        <FlexContainer direction="column" gap="5px" className={styles.details}>
          <h3>{name}</h3>

          <FlexContainer direction="row" alignItems="center" gap="5px">
            {status === 'DRAFT' && (
              <span className={styles.draftStatus}>DRAFT</span>
            )}
            <span className={styles.date}>
              {formatDateWithPastWeek(createdAt)}
            </span>
          </FlexContainer>

          <FlexContainer
            direction="column"
            gap="5px"
            className={styles.detailsBottom}
          >
            <FlexContainer
              alignItems="center"
              gap="5px"
              className={styles.metaItem}
            >
              <ResolutionsIcon width={16} height={16} />
              <span>{resolutions.length || 0} resolutions</span>
            </FlexContainer>
            <FlexContainer
              alignItems="center"
              gap="5px"
              className={styles.metaItem}
            >
              <ColumnsGap width={16} height={16} />
              <span>
                {(numberOfBannerMix || 0) * (resolutions?.length || 0)} banners
              </span>
            </FlexContainer>
          </FlexContainer>
        </FlexContainer>
      </Link>
    </div>
  );
};

export default BannerSetCard;
