export const generateFinalUrl = (adDetails) => {
  const params = [];

  params.push('cid={{CampaignID}}');
  params.push('cn={{BannerID}}');
  // params.push('name={{ImageName}}');

  if (adDetails.utmSource) params.push(`utm_source=${adDetails.utmSource}`);
  if (adDetails.utmMedium) params.push(`utm_medium=${adDetails.utmMedium}`);
  if (adDetails.utmCampaign)
    params.push(`utm_campaign=${adDetails.utmCampaign}`);

  const queryString = params.join('&');
  return `${adDetails.landingPageUrl}${queryString ? '?' + queryString : ''}`;
};

export const generateGoogleFinalUrl = (data) => {
  const params = [];

  params.push('cid={{CampaignID}}');
  params.push('cn={{BannerID}}');
  // params.push('name={{ImageName}}');

  if (data.campaignMedium) params.push(`utm_medium=${data.campaignMedium}`);
  if (data.campaignContent) params.push(`utm_content=${data.campaignContent}`);
  if (data.campaignName) params.push(`utm_campaign=${data.campaignName}`);
  if (data.campaignSource) params.push(`utm_source=${data.campaignSource}`);
  if (data.campaignTerm) params.push(`utm_term=${data.campaignTerm}`);

  const queryString = params.join('&');
  return `${data.websiteUrl}${queryString ? '?' + queryString : ''}`;
};
