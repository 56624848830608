import React, { useState, useEffect } from 'react';
import { XCircleFill } from 'react-bootstrap-icons';
import GeneralModalStyles from '../../Components/styles/GeneralModalStyles';
import FormStyles from '../../Components/styles/FormStyles';
import FlexContainer from '../../Components/styles/FlexContainer';

const BannerSetModal = ({
  show,
  handleClose,
  currentTitle,
  onSave,
  setBanner,
}) => {
  const [newTitle, setNewTitle] = useState(currentTitle);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setNewTitle(currentTitle);
  }, [currentTitle]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (newTitle.trim() === '') {
      return setBanner(true, 'bad', 'Title cannot be empty');
    }

    setLoading(true);
    try {
      await onSave(newTitle.trim());
      handleClose();
    } catch (err) {
      setBanner(true, 'bad', err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <GeneralModalStyles className="modal-wrapper">
        <div className="modal-body" style={{ width: '350px' }}>
          <button
            type="button"
            onClick={handleClose}
            className="modal-close-button"
          >
            <XCircleFill color="black" width={34} height={34} />
          </button>
        </div>
      </GeneralModalStyles>
    );
  }

  return (
    <GeneralModalStyles
      className="modal-wrapper"
      style={{ display: show ? 'block' : 'none' }}
    >
      <div className="modal-body" style={{ width: '350px' }}>
        <button
          type="button"
          onClick={handleClose}
          className="modal-close-button"
        >
          <XCircleFill color="black" width={34} height={34} />
        </button>

        <FormStyles onSubmit={handleSubmit}>
          <FlexContainer direction="column" gap="40px" alignItems="center">
            <div className="modal-title">Edit Campaign Title</div>

            <div className="input-wrapper" style={{ width: '100%' }}>
              <label>Title</label>
              <input
                type="text"
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
                placeholder="Enter new title"
                autoFocus
              />
            </div>

            <div className="button-wrapper">
              <button type="submit" className="confirm-button">
                Save
              </button>
            </div>
          </FlexContainer>
        </FormStyles>
      </div>
    </GeneralModalStyles>
  );
};

export default BannerSetModal;
