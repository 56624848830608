import React from 'react';
import { useParams } from 'react-router-dom';
import {
  LayoutWtf,
  Collection,
  UiChecksGrid,
  XOctagon,
} from 'react-bootstrap-icons';
import DefaultLayout from '../../../Components/DefaultLayout';
import TopBar from '../../TopBar/TopBar';
import styles from './Mixing.module.css';
import LoadingAnimation from './LoadingAnimation';
import useMixing from './useMixing';

const Mixing = () => {
  const { id } = useParams();
  const { bannerSet, stats, isLoading, progress, error, timeRemaining } =
    useMixing(id);

  const infoArray = [
    {
      label: `Total image${stats?.totalBanners !== 1 ? 's' : ''}`,
      value: stats?.totalBanners * bannerSet?.resolutions?.length,
      icon: <LayoutWtf />,
    },
    {
      label: `Variation${stats?.totalBanners !== 1 ? 's' : ''}`,
      value: stats?.totalBanners,
      icon: <Collection />,
    },
    {
      label: `Mixed banner${stats?.mixedBanners !== 1 ? 's' : ''}`,
      value: stats?.mixedBanners * bannerSet?.resolutions?.length,
      icon: <UiChecksGrid />,
    },
  ];

  return (
    <DefaultLayout>
      <div className={styles.root}>
        <TopBar title="Banner mixer" network={bannerSet?.platform} />
        {isLoading && <div>Loading...</div>}
        {!isLoading && (
          <div className={styles.contentWrapper}>
            <div className={styles.loadingAnimation}>
              {error && <XOctagon className={styles.errorIcon} />}
              {!error && <LoadingAnimation />}
            </div>
            <div className={styles.progressInfoWrapper}>
              <h2 className={styles.progressTitle}>
                {error ? 'Mixing failed' : 'Mixing in progress...'}
              </h2>
              {error && <div className={styles.errorMessage}>{error}</div>}
              {!error && (
                <>
                  <div className={styles.progressBar}>
                    <div
                      className={styles.progressBarFill}
                      style={{ width: `${progress}%` }}
                    />
                    <div
                      className={styles.progressBarTextHolder}
                      style={{ width: `${progress}%` }}
                    >
                      <span className={styles.progressBarText}>
                        {progress}%
                      </span>
                    </div>
                  </div>
                  {timeRemaining && (
                    <div className={styles.timeRemaining}>
                      Estimated time remaining: {timeRemaining}
                    </div>
                  )}
                </>
              )}
              <div className={styles.progressStats}>
                {infoArray.map((info) => {
                  if (typeof info.value !== 'number' || info.value == null) {
                    return null;
                  }
                  return (
                    <div key={info.label} className={styles.infoStatsElement}>
                      {info.icon}
                      {info.value} {info.label}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </DefaultLayout>
  );
};

export default Mixing;
