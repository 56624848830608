import React, { useMemo } from 'react';
import { Robot } from 'react-bootstrap-icons';
import toast from 'react-hot-toast';
import Grid from '@material-ui/core/Grid';
import { GalleryCard } from '../GalleryCard/GalleryCard';
import ResolutionButtons from '../../FormComponents/ResolutionButtons';
import FlexContainer from '../../styles/FlexContainer';
import Delimiter from '../../styles/Delimiter';
import {
  AllBannersGroup,
  UngroupedBannersGroup,
  useGalleryContext,
} from '../Gallery.context';
import ButtonsGroup from '../../FormComponents/ButtonsGroup';

import Switch from '../../FormComponents/Switch';
import Button from '../../FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../shared/enums/buttonEnum';
import ConfirmModal from '../../../ComponentsV2/ConfirmModal/ConfirmModal';
import styles from './GalleryList.module.scss';
import { useGalleryListApi } from './useGalleryListApi';

export const GalleryList = () => {
  const {
    aiScores: { showAiScores, setShowAiScores, aiScoresData },
    filters: {
      bannerGroups,
      selectedBannerGroup,
      setSelectedBannerGroup,
      networkResolutions,
      selectedResolution,
      setSelectedResolution,
    },
    bannerGroupsMap,
    bannersList,
  } = useGalleryContext();

  const { fetchingAiScores, handleGenerateAiScores } = useGalleryListApi();

  const filteredBannersList = useMemo(() => {
    if (selectedBannerGroup?.value === AllBannersGroup.value) {
      return bannersList;
    }

    return bannersList.filter((banner) => {
      const currentBannerGroups = bannerGroupsMap[banner._id];

      if (!currentBannerGroups?.length) {
        return selectedBannerGroup?.value === UngroupedBannersGroup.value;
      }

      return currentBannerGroups
        .map((group) => group._id)
        .includes(selectedBannerGroup.value);
    });
  }, [selectedBannerGroup, bannersList, bannerGroupsMap]);

  const onRobotIconClick = () => {
    ConfirmModal({
      title: 'Regenerate A.I. scores',
      subMessage: [
        'This will regenerate A.I. scores for all banners.',
        'This action cannot be undone.',
      ],
      confirmText: 'Regenerate',
      cancelText: 'Cancel',
      isDanger: true,
      onConfirm: () => {
        toast.promise(
          new Promise((resolve) => {
            handleGenerateAiScores();
            resolve();
          }),
          {
            loading: 'Regenerating A.I. scores...',
            success: 'A.I. scores have been regenerated successfully!',
            error: 'Failed to regenerate A.I. scores.',
          },
        );
      },
    });
  };

  const AiScoresSection = () => {
    if (fetchingAiScores) {
      return (
        <div>
          <FlexContainer gap="8px" alignItems="center">
            <Robot size={19} className="color-primary" />
            <span className={styles.aiScoresText}>Loading A.I. scores ...</span>
          </FlexContainer>
        </div>
      );
    }

    if (aiScoresData?.size) {
      return (
        <div>
          <FlexContainer gap="8px" alignItems="center">
            <Robot
              size={19}
              className="color-primary"
              onClick={onRobotIconClick}
            />
            <span className={styles.aiScoresText}>A.I. scores: </span>
            <Switch
              className={styles.aiScoresSwitch}
              checked={showAiScores}
              onChange={(value) => setShowAiScores(value)}
            />
          </FlexContainer>
        </div>
      );
    }

    return (
      <div>
        <Button
          variant={ButtonVariantEnum.OUTLINED}
          size={ButtonSizeEnum.XS}
          onClick={handleGenerateAiScores}
        >
          <FlexContainer
            gap="8px"
            alignItems="center"
            className={styles.aiScoresText}
          >
            <Robot size={19} />
            <span className={styles.aiScoresText}>Generate A.I. scores</span>
          </FlexContainer>
        </Button>
      </div>
    );
  };

  return (
    <FlexContainer direction="column" gap="20px">
      <ResolutionButtons
        brighterButtons
        hideLabel
        showStacked
        networkResolutions={networkResolutions}
        selectedResolutions={[selectedResolution]}
        onResolutionToggle={setSelectedResolution}
      />

      <ButtonsGroup
        items={bannerGroups}
        selectedItems={[selectedBannerGroup]}
        onChange={(values) => {
          setSelectedBannerGroup(values[0]);
        }}
      />

      <AiScoresSection />

      <Delimiter />

      <Grid container spacing={3}>
        {filteredBannersList.map((banner) => (
          <Grid key={banner._id} item xs={4}>
            <GalleryCard banner={banner} />
          </Grid>
        ))}
      </Grid>
    </FlexContainer>
  );
};
