import React, { useState } from 'react';
import { useMixer } from '../../MixerContext';
import BannerElementType from '../../../../../shared/enums/bannerElementType';
import TextElementSets from '../Fields/Text/TextElementSets';
import BannerTextModal from '../../../Creator/Elements/BannerText/Modal/TextModal';
import BannerModal from '../../../Creator/BannerModal';
import VariationStatusEnum from '../../../../../shared/enums/variationStatus';

const TextElementOptions = ({ id }) => {
  const {
    bannerSet,
    previewOptions,
    elementVariants,
    handleAddTextElementVariant,
    handleUpdateElementVariant,
    handleRemoveElementVariant,
    handlePreviewOption,
    selectedResolution,
  } = useMixer();

  const [modalOpen, setModalOpen] = useState(false);
  const [currentVariant, setCurrentVariant] = useState(null);

  const baseSet = bannerSet.banners[0].elements?.find(
    (el) => el._id === id,
  )?.variations;

  const preparedBaseSet = Object.entries(baseSet).reduce(
    (acc, [resolution, data]) => {
      acc[resolution] = {
        status:
          data.status ||
          (data.options?.src
            ? VariationStatusEnum.ENABLED
            : VariationStatusEnum.DISABLED),
        id: data._id,
        options: data.options,
      };
      return acc;
    },
    {},
  );

  const handleAddNewSet = () => {
    setCurrentVariant(null);
    setModalOpen(true);
  };

  const handleEditVariant = ({ variantId, variations }) => {
    setCurrentVariant({ id: variantId, variations });
    setModalOpen(true);
  };

  const handleSaveVariant = (variantId, variations) => {
    if (currentVariant) {
      handleUpdateElementVariant({
        variantId: currentVariant.id,
        updates: { variations },
        type: BannerElementType.TEXT,
      });

      const previewElement = Object.entries(previewOptions.elements || {}).find(
        // eslint-disable-next-line no-unused-vars
        ([_, value]) => value.variantId === currentVariant.id,
      );

      if (previewElement) {
        const [elementId] = previewElement;
        handlePreviewOption({
          name: 'elementText',
          value: {
            elementId,
            variantId: currentVariant.id,
            variations,
          },
        });
      }
    } else {
      handleAddTextElementVariant({ id, variations });
    }
    setModalOpen(false);
  };

  const handleRemoveSet = (variantId) => {
    handleRemoveElementVariant(variantId);
  };

  const handlePreviewClick = (variantId) => {
    const isCurrentlyActive =
      previewOptions.elements?.[id]?.variantId === variantId;

    if (isCurrentlyActive || !variantId) {
      handlePreviewOption({
        name: 'elementText',
        value: {
          elementId: id,
          variantId: null,
        },
      });
      return;
    }

    const selectedVariant = elementVariants.find((v) => v.id === variantId);
    if (!selectedVariant) return;

    handlePreviewOption({
      name: 'elementText',
      value: {
        elementId: id,
        variantId: variantId,
        variations: selectedVariant.variations,
      },
    });
  };

  return (
    <>
      <TextElementSets
        baseSet={preparedBaseSet}
        customVariants={elementVariants
          .filter((v) => v.type === BannerElementType.TEXT)
          .filter((v) => v.baseId === id)}
        onAddNewSet={handleAddNewSet}
        onRemoveSet={handleRemoveSet}
        onPreviewClick={handlePreviewClick}
        handleEditVariant={handleEditVariant}
        activeSet={previewOptions.elements?.[id]?.variantId}
      />
      {modalOpen && (
        <BannerModal
          title={'Edit text'}
          open={modalOpen}
          handleClose={() => setModalOpen(false)}
        >
          <BannerTextModal
            resolutions={bannerSet.resolutions.map(
              (res) => `${res.width}x${res.height}`,
            )}
            id={currentVariant?.id}
            edit={!!currentVariant}
            options={currentVariant?.options ?? baseSet?.options}
            variations={currentVariant?.variations ?? baseSet}
            closeModal={() => setModalOpen(false)}
            onSave={handleSaveVariant}
            selectedResolution={selectedResolution}
          />
        </BannerModal>
      )}
    </>
  );
};

export default TextElementOptions;
