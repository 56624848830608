import React from 'react';
import cx from 'classnames';
import styles from '../styles/Cards.module.scss';
import { useFormCard } from '../hooks/useFormCard';
import { ReactComponent as IconAds1 } from '../../../images/iconAds1.svg';
import { ReactComponent as IconAds2 } from '../../../images/iconAds2.svg';
import AD_TYPE from '../constants/adTypeEnum';
import styles2 from './GoogleAdType.module.css';

const GoogleAdType = ({ disabled }) => {
  const { values, setFieldValue } = useFormCard();

  const onClick = (type) => {
    setFieldValue('adType', type);
  };

  return (
    <div className={cx(styles2.root, { [styles.disabled]: disabled })}>
      <h2 className={styles.title}>Ads Type</h2>
      <div className={styles2.adTypesWrapper}>
        <div
          className={cx(
            styles2.adTypeBox,
            values.adType === AD_TYPE.CLASSIC && styles2.active,
          )}
          onClick={() => onClick(AD_TYPE.CLASSIC)}
        >
          <IconAds1 />
          <div>Classic Ads</div>
        </div>
        <div
          className={cx(
            styles2.adTypeBox,
            values.adType === AD_TYPE.RESPONSIVE && styles2.active,
          )}
          onClick={() => onClick(AD_TYPE.RESPONSIVE)}
        >
          <IconAds2 />
          <div>Responsive ads</div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdType;
