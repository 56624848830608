import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useFormikContext } from 'formik';
import fetchInstance from '../../../../utils/fetchInstance';

export const useBannerPlacementsCard = ({
  onComplete,
  availableResolutions,
}) => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [placements, setPlacements] = useState([]);
  const { setFieldValue, values, errors } = useFormikContext();

  const fetchBannerGroups = async () => {
    try {
      setLoading(true);
      const response = await fetchInstance(`/getBannerGroups/${id}`);

      if (!response.ok) {
        throw new Error('Failed to fetch banner groups');
      }

      const data = await response.json();
      if (data.bannerGroups) {
        setGroups(data.bannerGroups);
      } else {
        setGroups([]);
      }
    } catch (error) {
      console.error('Error fetching banner groups:', error);
      toast.error('Failed to fetch banner groups');
      setGroups([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchBannerGroups();
    }
  }, [id]);

  const handleGroupChange = (event) => {
    const groupId = event.target.value;

    let group = '';
    if (groupId === 'all') {
      group = { _id: 'all', name: 'All' };
    }

    if (groupId !== 'all') {
      group = groups.find((g) => g._id === groupId);
    }

    setSelectedGroup(group);
    setFieldValue('banners.group', group);

    checkAndTriggerComplete(group, placements);
  };

  const handleAddPlacement = (placementKey, placementData) => {
    const newPlacement = {
      id: placementKey,
      name: placementData.name,
      subCategories: placementData.subCategories,
      resolutions: [],
    };

    const updatedPlacements = [...placements, newPlacement];
    setPlacements(updatedPlacements);
    setFieldValue('banners.placements', updatedPlacements);
  };

  const handleResolutionToggle = (placementId, resolution) => {
    const updatedPlacements = placements.map((placement) => {
      if (placement.id === placementId) {
        const currentResolutions = placement.resolutions || [];
        const newResolutions = currentResolutions.includes(resolution)
          ? []
          : [resolution];

        return {
          ...placement,
          resolutions: newResolutions,
        };
      }
      return placement;
    });

    setPlacements(updatedPlacements);
    setFieldValue('banners.placements', updatedPlacements);
    checkAndTriggerComplete(selectedGroup, updatedPlacements);
  };

  const handleRemovePlacement = (placementId) => {
    const updatedPlacements = placements.filter((p) => p.id !== placementId);
    setPlacements(updatedPlacements);
    setFieldValue('banners.placements', updatedPlacements);
    checkAndTriggerComplete(selectedGroup, updatedPlacements);
  };

  const checkAndTriggerComplete = (group, currentPlacements) => {
    if (group && currentPlacements.length > 0) {
      let hasValidPlacement = currentPlacements.some(
        (placement) => placement.resolutions.length > 0,
      );

      if (!values.banners.platforms) {
        hasValidPlacement = false;
      }

      if (hasValidPlacement) {
        onComplete({
          group,
          platforms: values.banners.platforms,
          placements: currentPlacements,
        });
      }
    }
  };

  return {
    loading,
    selectedGroup,
    availableResolutions,
    placements,
    handleGroupChange,
    handleAddPlacement,
    handleResolutionToggle,
    handleRemovePlacement,
    setFieldValue,
    values,
    errors,
    groupOptions: [
      { value: '', label: 'Select banners group' },
      { value: 'all', label: 'All' },
      ...groups.map((group) => ({
        value: group._id,
        label: group.name,
      })),
    ],
  };
};
