export const FACEBOOK_PLACEMENTS = {
  FEEDS: {
    name: 'Feeds',
    subCategories: [
      'Facebook Feed',
      'Facebook Profile Feed',
      'Instagram Feed',
      'Instagram Profile Feed',
      'Facebook Marketplace',
      'Facebook Video Feeds',
      'Facebook Right Column',
      'Instagram Explore',
      'Instagram Explore Home',
      'Messenger Inbox',
    ],
  },
  STORIES_AND_REELS: {
    name: 'Stories and Reels',
    subCategories: [
      'Instagram Stories',
      'Facebook Stories',
      'Messenger Stories',
      'Instagram Reels',
      'Instagram Profile Reels',
      'Facebook Reels',
    ],
  },
  IN_STREAM_ADS: {
    name: 'In-Stream Ads',
    subCategories: ['Facebook In-Stream Videos', 'Ads on Facebook Reels'],
  },
  SEARCH: {
    name: 'Search',
    subCategories: ['Facebook Search Results', 'Instagram Search Results'],
  },
  MESSAGES: {
    name: 'Messages',
    subCategories: ['Messenger Sponsored Messages'],
  },
  APPS_AND_SITES: {
    name: 'Apps and Sites',
    subCategories: [
      'Audience Network native, banner and interstitial',
      'Audience Network Rewarded Videos',
    ],
  },
};
