import React from 'react';
import toast from 'react-hot-toast';
import { nanoid } from 'nanoid';
import { useMixer } from '../../MixerContext';
import ImagesSets from '../Fields/Image/ImagesSets';
import useFileUpload from '../../../../../hooks/useFileUpload';
import VariationStatusEnum from '../../../../../shared/enums/variationStatus';

const BackgroundSets = () => {
  const {
    bannerSet,
    handleAddCustomBackgroundVariant,
    customBackgroundVariants,
    handleRemoveCustomBackgroundVariant,
    handleUpdateBackgroundVariant,
    previewOptions,
    handlePreviewOption,
  } = useMixer();
  const { uploadFile } = useFileUpload();

  const resolutions = bannerSet.resolutions.map(
    (res) => `${res.width}x${res.height}`,
  );

  const isEnabled = (variation) => {
    if (variation?.src) {
      return VariationStatusEnum.ENABLED;
    }
    return VariationStatusEnum.FORCE_DISABLED;
  };

  const handleAddNewSet = () => {
    const variations = resolutions.reduce((acc, resolution) => {
      acc[resolution] = {
        src: '',
        status: isEnabled(bannerSet?.banners[0]?.backgrounds?.[resolution]),
        id: nanoid(),
      };
      return acc;
    }, {});
    const newSet = { variations, id: nanoid() };
    handleAddCustomBackgroundVariant(newSet);
  };

  const handlePreview = (variants) => {
    const isActive = previewOptions.backgroundImages === variants;
    if (isActive) {
      handlePreviewOption({ name: 'backgroundImages', value: null });
      return;
    }
    handlePreviewOption({ name: 'backgroundImages', value: variants });
  };

  const handleRemove = (setId, variants) => {
    const isActive = previewOptions.backgroundImages === variants;
    if (isActive) {
      handlePreviewOption({ name: 'backgroundImages', value: null });
    }
    handleRemoveCustomBackgroundVariant(setId);
  };

  const handleImageUpload = async (
    files,
    { resolution, setId, isActivePreviewSet },
  ) => {
    toast.promise(
      (async () => {
        const uploadedFiles = await uploadFile(files[0]);
        handleUpdateBackgroundVariant({
          resolution,
          setId,
          status: VariationStatusEnum.ENABLED,
          filePath: uploadedFiles[0].path,
          isActivePreviewSet,
        });
      })(),
      {
        loading: 'Uploading image...',
        success: 'Image uploaded successfully',
        error: 'Failed to upload image',
      },
    );
  };

  const handleImageRemove = ({ resolution, setId, isActivePreviewSet }) => {
    handleUpdateBackgroundVariant({
      resolution,
      setId,
      filePath: '',
      isActivePreviewSet,
    });
  };

  const handleImageDisable = ({
    resolution,
    setId,
    isDisabled,
    isActivePreviewSet,
  }) => {
    handleUpdateBackgroundVariant({
      resolution,
      setId,
      status: isDisabled
        ? VariationStatusEnum.ENABLED
        : VariationStatusEnum.DISABLED,
      isActivePreviewSet,
    });
  };

  return (
    <ImagesSets
      baseSet={bannerSet.banners[0].backgrounds}
      customVariants={customBackgroundVariants}
      onAddNewSet={handleAddNewSet}
      onRemoveSet={handleRemove}
      onPreviewClick={handlePreview}
      activeSet={previewOptions.backgroundImages}
      onImageUpload={handleImageUpload}
      onImageRemove={handleImageRemove}
      onImageDisable={handleImageDisable}
      preserveResolutionAspect
      isBackground
    />
  );
};

export default BackgroundSets;
