import React, { useState, useEffect } from 'react';
import { XCircleFill } from 'react-bootstrap-icons';
import FormStyles from '../styles/FormStyles';
import Title from '../styles/TitleStyle';
import GeneralModalStyles from '../styles/GeneralModalStyles';
import fetchInstance from '../../utils/fetchInstance';
import FlexContainer from '../styles/FlexContainer';

const obj = {
  active: false,
  endDate: '',
  startDate: '',
  url: '',
  name: '',
  notes: '',
  publikLink: {
    expires: {},
  },
  parent: null,
  isCampaignGroup: false,
};

const CampaignDetails = ({
  selectedCampaign,
  handleCloseModal,
  updateCampaign,
  showModal,
  setBanner,
  extractCSRF,
  clientId,
  userId,
  parentCampaign,
  isCampaignGroup = false,
}) => {
  const [currentCampaign, setCurrentCampaign] = useState(() => {
    if (selectedCampaign) {
      return {
        ...selectedCampaign,
        url: selectedCampaign.url || '',
        parent: selectedCampaign.parent || selectedCampaign.group || null,
        group: selectedCampaign.group || selectedCampaign.parent || null,
      };
    }
    return obj;
  });

  const [campaignGroups, setCampaignGroups] = useState([]);
  const [parentId, setParentId] = useState(null);

  const fetchCampaignGroups = async () => {
    if (!clientId) return;

    try {
      const res = await fetchInstance(`/getGroups/${clientId}`);
      if (!res.ok) throw new Error(res.statusText);
      const data = await res.json();

      if (data.error) {
        throw new Error(data.message);
      }

      setCampaignGroups(data.groups || []);

      if (currentCampaign.group?._id || currentCampaign.parent?._id) {
        const groupId =
          currentCampaign.group?._id || currentCampaign.parent?._id;
        const updatedGroup = data.groups?.find((g) => g._id === groupId);
        if (updatedGroup) {
          setCurrentCampaign((prev) => ({
            ...prev,
            group: updatedGroup,
            parent: updatedGroup,
          }));
        }
      }
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  useEffect(() => {
    console.log('showModal', showModal);
    console.log('clientId', clientId);
    if (showModal && clientId) {
      console.log('fetching campaign groups');
      fetchCampaignGroups();
    }
    if (parentCampaign) {
      setParentId(parentCampaign._id);
    }
  }, [showModal, clientId, parentCampaign]);

  const handlePreviewInputChange = (ev) => {
    const editCampaign = { ...currentCampaign };
    const changedProperty = ev.currentTarget.name;
    let newValue = ev.currentTarget.value;

    if (ev.currentTarget.type === 'checkbox') {
      newValue = ev.currentTarget.checked;
    }

    if (changedProperty === 'parent') {
      const selectedGroup = campaignGroups.find((g) => g._id === newValue);
      editCampaign['group'] = selectedGroup || null;
      editCampaign['parent'] = newValue || null;
    } else {
      editCampaign[changedProperty] = newValue;
    }

    setCurrentCampaign(editCampaign);
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    try {
      await updateCampaign(currentCampaign);
    } catch (err) {
      setBanner(true, 'bad', err.message);
    }
  };

  return (
    <GeneralModalStyles
      className="modal-wrapper"
      style={{ opacity: showModal ? 1 : 0 }}
    >
      <div className="modal-body">
        <button
          type="button"
          onClick={handleCloseModal}
          className="modal-close-button"
        >
          <XCircleFill color="black" width={34} height={34} />
        </button>

        <FormStyles onSubmit={handleSubmit}>
          <FlexContainer direction="column" gap="40px" alignItems="center">
            <div className="modal-title">
              {selectedCampaign
                ? `Edit ${selectedCampaign.name}`
                : `Add new campaign ${isCampaignGroup ? 'group' : ''}`}
            </div>
            <div style={{ width: '100%' }}>
              <div className="group">
                <label>Name</label>
                <input
                  className="formInput"
                  type="text"
                  name="name"
                  value={currentCampaign.name}
                  onChange={handlePreviewInputChange}
                  required
                />
              </div>

              {!isCampaignGroup && (
                <div className="group">
                  <label>Campaign Group</label>
                  <select
                    name="parent"
                    onChange={handlePreviewInputChange}
                    value={
                      currentCampaign.group?._id || currentCampaign.parent || ''
                    }
                  >
                    <option value="">-- Choose a campaign group --</option>
                    {campaignGroups.map((group) => {
                      return (
                        <option key={group._id} value={group._id}>
                          {group.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}

              <div className="group">
                <label>URL</label>
                <input
                  className="formInput"
                  type="text"
                  name="url"
                  value={currentCampaign.url}
                  onChange={handlePreviewInputChange}
                  required
                />
              </div>

              <div className="group">
                <label>LP assets base URL</label>
                <div style={{ lineHeight: '1.2', marginBottom: '10px' }}>
                  <small>
                    Optional. Base URL for landing page assets. <br /> When set,
                    it will be used to construct the complete URL path for
                    assets.
                  </small>
                </div>
                <input
                  className="formInput"
                  type="url"
                  name="lpAssetsBaseUrl"
                  value={currentCampaign.lpAssetsBaseUrl}
                  onChange={handlePreviewInputChange}
                />
              </div>

              <div
                className="group"
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <label>Status</label>
                <div style={{ marginLeft: '1rem' }}>
                  <input
                    id="singleToggle"
                    name="active"
                    type="checkbox"
                    checked={!!currentCampaign.active}
                    onChange={handlePreviewInputChange}
                  />
                  <label htmlFor="singleToggle" />
                </div>
              </div>

              <div className="group">
                <label>Campaign Start Date</label>
                <input
                  name="startDate"
                  type="date"
                  value={
                    currentCampaign && currentCampaign.startDate
                      ? new Date(currentCampaign.startDate)
                          .toISOString()
                          .substr(0, 10)
                      : ''
                  }
                  onChange={handlePreviewInputChange}
                />
              </div>

              <div className="group">
                <label>Campaign End Date</label>
                <input
                  name="endDate"
                  type="date"
                  min={currentCampaign.startDate}
                  value={
                    currentCampaign && currentCampaign.endDate
                      ? new Date(currentCampaign.endDate)
                          .toISOString()
                          .substr(0, 10)
                      : ''
                  }
                  onChange={handlePreviewInputChange}
                />
              </div>

              <div className="group">
                <label>Notes</label>
                <textarea
                  name="notes"
                  onChange={handlePreviewInputChange}
                  className="formInput"
                  value={currentCampaign.notes}
                />
              </div>
            </div>

            <div className="button-wrapper">
              <button className="confirm-button">Save</button>
            </div>
          </FlexContainer>
        </FormStyles>
      </div>
    </GeneralModalStyles>
  );
};

export default CampaignDetails;
