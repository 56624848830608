const generateBannerHTML = (combination, selectedResolution, fonts) => {
  const [width, height] = selectedResolution.split('x').map(Number);

  const getTransformString = (transform) => {
    if (!transform) return 'none';

    const transformParts = [];

    if (transform.matrix3d) {
      transformParts.push(`matrix3d(${transform.matrix3d.join(',')})`);
    }

    if (
      transform.translate &&
      (transform.translate[0] !== 0 || transform.translate[1] !== 0)
    ) {
      transformParts.push(
        `translate(${transform.translate[0]}px, ${transform.translate[1]}px)`,
      );
    }

    if (transform.rotate) {
      transformParts.push(`rotate(${transform.rotate})`);
    }

    if (
      (transform.scaleX && transform.scaleX !== 1) ||
      (transform.scaleY && transform.scaleY !== 1)
    ) {
      transformParts.push(
        `scale(${transform.scaleX || 1}, ${transform.scaleY || 1})`,
      );
    }

    return transformParts.length > 0 ? transformParts.join(' ') : 'none';
  };

  // Colectăm fonturile unice folosite în elemente
  const usedFonts = Object.values(combination.elements)
    .filter((element) => element.options?.font)
    .reduce((acc, element) => {
      const fontName = element.options.font;
      if (!acc.includes(fontName) && fonts[fontName]) {
        acc.push(fontName);
      }
      return acc;
    }, []);

  // Generăm @font-face pentru fonturile custom și Google
  const fontFaces = usedFonts
    .map((fontName) => {
      const font = fonts[fontName];

      if (font.isSystem) {
        return ''; // Nu generăm @font-face pentru fonturile sistem
      }

      if (font.isGoogle) {
        return `@import url('${font.url}');`;
      }

      // Pentru fonturi custom (uploadate)
      return `
      @font-face {
        font-family: '${font.name}';
        src: url('${font.path}') format('truetype');
      }
    `;
    })
    .join('\n');

  return `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          ${fontFaces}
          body { 
            margin: 0;
            width: ${width}px;
            height: ${height}px;
            transform-origin: 0 0;
            background-color: ${combination.rootOptions.backgroundColor || '#fff'};
            overflow: hidden;
            background-image: ${
              combination.background?.src
                ? `url(${combination.background.src})`
                : 'transparent'
            };
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
          .banner-container {
            width: 100%;
            height: 100%;
            border: ${combination.rootOptions.borderThickness || '0'} ${combination.rootOptions.borderStyle || 'solid'} ${combination.rootOptions.borderColor || 'transparent'};
            padding: ${combination.rootOptions.innerPadding || '0'};
            position: relative;
            overflow: hidden;
            box-sizing: border-box;
            
          }
        </style>
      </head>
      <body>
        <div class="banner-container">
          ${Object.entries(combination.elements)
            .sort(
              (a, b) =>
                (a[1].frame?.['z-index'] || 0) - (b[1].frame?.['z-index'] || 0),
            )
            .map(([, element]) => {
              const displayStyle = element.isVisible ? '' : 'display: none;';

              if (element?.options?.src || element?.src) {
                return `<img 
                  style="position: absolute; ${displayStyle} ${
                    element.frame
                      ? `
                    left: ${element.frame.left};
                    top: ${element.frame.top};
                    width: ${element.frame.width};
                    height: ${element.frame.height};
                    transform: ${getTransformString(element.frame.transform)};
                  `
                      : ''
                  } ${element.options?.style || ''}"
                  src="${element.options?.src || element.src}"
                />`;
              }
              if (element.options?.content) {
                return `<${element.options.tag || 'span'}
                  style="position: absolute; ${displayStyle} ${
                    element.frame
                      ? `
                    left: ${element.frame.left};
                    top: ${element.frame.top};
                    width: ${element.frame.width};
                    height: ${element.frame.height};
                    transform: ${getTransformString(element.frame.transform)};
                  `
                      : ''
                  }
                  font-family: ${element.options.font || 'Arial'};
                  font-size: ${element.options.size || '16px'};
                  color: ${element.options.color || '#000'};
                  line-height: ${element.options.lineHeight || '1.1'};
                  text-align: ${element.options.position || 'left'};
                  ${element.options.style || ''}"
                >${element.options.content}</${element.options.tag || 'span'}>`;
              }
              return '';
            })
            .join('\n')}
        </div>
      </body>
    </html>
  `;
};

export default generateBannerHTML;
