import React, { useEffect, useRef, useState, useMemo } from 'react';
import cx from 'classnames';
import config from '../../../config';
import BannerElement from '../Creator/Elements/BannerElement';
import { ContextMenuProvider } from '../Creator/Elements/ContextMenu/CustomContextMenu';
import VariationStatusEnum from '../../../shared/enums/variationStatus';
import styles from './Preview.module.css';
import './preview.scss';

const PreviewContent = ({
  width,
  height,
  backgroundSrc,
  elements = [],
  className,
  customStyles = {},
  elementProps = {},
}) => {
  const containerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [scaledHeight, setScaledHeight] = useState(height);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    if (!containerRef.current || !width) return;

    const calculateScale = () => {
      const availableWidth = containerRef.current.parentElement.offsetWidth;
      const maxHeight = config.bannerWizard.maxHeight;

      const widthScale = availableWidth / width;
      const heightScale = maxHeight / height;

      const newScale = Math.min(
        1,
        Math.max(0.1, Math.min(widthScale, heightScale)),
      );
      setScale(newScale);
      setScaledHeight(height * newScale);
      setReadyToRender(true);
    };

    calculateScale();

    const resizeObserver = new ResizeObserver(calculateScale);
    resizeObserver.observe(containerRef.current.parentElement);

    return () => {
      resizeObserver.disconnect();
    };
  }, [width, height]);

  const baseStyles = useMemo(
    () => ({
      width: `${width}px`,
      height: `${height}px`,
      transform: `scale(${scale})`,
      transformOrigin: 'center center',
      backgroundColor: '#ffffff',
      backgroundImage: backgroundSrc ? `url(${backgroundSrc})` : 'none',
      backgroundSize: '100% 100%',
      position: 'relative',
      ...customStyles,
    }),
    [width, height, scale, backgroundSrc, customStyles],
  );

  return (
    <div
      className={cx(
        styles.container,
        elementProps.disabledElementActions && styles.disabledActions,
      )}
      ref={containerRef}
    >
      <div
        className={styles.scaleWrapper}
        style={{ height: `${scaledHeight}px` }}
      >
        <div className={styles.previewContainer}>
          {readyToRender && (
            <div
              id="banner-view"
              className={cx(
                'banner-preview-container',
                styles.preview,
                className,
              )}
              style={baseStyles}
            >
              {elements.map((element) => {
                if (
                  element?.variations?.[elementProps.currentResolution]
                    ?.status === VariationStatusEnum.DISABLED
                ) {
                  return null;
                }

                return (
                  <BannerElement
                    key={`${element.id}-${elementProps.currentResolution}`}
                    {...element}
                    {...elementProps}
                    container={document.querySelector('#banner-view')}
                    offsetH={height}
                    offsetW={width}
                    previewScale={scale}
                    bannerRect={containerRef.current?.getBoundingClientRect()}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const BannerPreview = (props) => {
  const content = <PreviewContent {...props} />;

  if (props.renderWrapper) {
    return props.renderWrapper(
      <ContextMenuProvider>{content}</ContextMenuProvider>,
    );
  }

  return <ContextMenuProvider>{content}</ContextMenuProvider>;
};

export default BannerPreview;
