import { useState, useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import fetchFbInstance from '../../../../utils/fetchFbInstance';
import fetchInstance from '../../../../utils/fetchInstance';

export const useFacebookCampaign = ({ unlockedSteps, setUnlockedSteps }) => {
  const { id } = useParams();
  const [bannerSet, setBannerSet] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [availableResolutions, setAvailableResolutions] = useState([]);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [initLoading, setInitLoading] = useState(true);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const checkIfUserIsLoggedIn = async () => {
      try {
        const response = await fetchInstance('/checkuser');
        const data = await response.json();
        if (!data.user.facebookAccount.name) {
          setLoggedIn(false);
          return;
        }
        setLoggedIn(true);
      } catch (error) {
        console.error('Error checking if user is logged in:', error);
        toast.error('Failed to check if user is logged in Facebook.');
        setLoggedIn(false);
      }
    };

    checkIfUserIsLoggedIn();

    const fetchBannerSet = async () => {
      try {
        const response = await fetchInstance(`/getBannerSet/${id}`);

        if (!response.ok) {
          throw new Error('Failed to fetch banner set');
        }

        const data = await response.json();

        setBannerSet(data);
        if (data.resolutions) {
          const resolutions = data.resolutions.map(
            (res) => `${res.width}x${res.height}`,
          );
          setAvailableResolutions(resolutions);
        }
      } catch (error) {
        console.error('Error fetching banner set:', error);
        toast.error('Failed to fetch banner set.');
        setBannerSet(null);
        setAvailableResolutions([]);
      } finally {
        setInitLoading(false);
      }
    };

    fetchBannerSet();
  }, [id]);

  const validateSubmission = (values) => {
    const hasValidDestination =
      values.destination.account &&
      values.destination.campaign &&
      values.destination.adSet &&
      values.destination.page;

    const hasValidBanners =
      values.banners.group &&
      values.banners.placements?.length > 0 &&
      values.banners.placements.some(
        (placement) => placement.resolutions?.length > 0,
      );

    const hasValidAdDetails = values.adDetails.headline;

    return hasValidDestination && hasValidBanners && hasValidAdDetails;
  };

  const handleStepComplete = useCallback(
    (step, values) => {
      switch (step) {
        case 'destination':
          if (
            values.destination.account &&
            values.destination.campaign &&
            values.destination.adSet &&
            values.destination.page
          ) {
            setCurrentStep(2);
            if (!unlockedSteps.includes(2)) {
              setUnlockedSteps((prev) => [...prev, 2]);
            }
          }
          break;

        case 'banners':
          const hasValidPlacement = values.banners.placements?.some(
            (placement) => placement.resolutions?.length > 0,
          );

          if (values.banners.group && hasValidPlacement) {
            setCurrentStep(3);
            if (!unlockedSteps.includes(3)) {
              setUnlockedSteps((prev) => [...prev, 3]);
            }
          }
          break;

        case 'adDetails':
          if (
            values.adDetails.name &&
            values.adDetails.finalUrl &&
            values.adDetails.headline &&
            values.adDetails.primaryText
          ) {
            setCurrentStep(4);
            if (!unlockedSteps.includes(4)) {
              setUnlockedSteps((prev) => [...prev, 4]);
            }
          }
          break;

        default:
          break;
      }
    },
    [unlockedSteps, setUnlockedSteps],
  );

  const handleSubmit = useCallback(
    async (values) => {
      try {
        setSuccess(null);
        if (!validateSubmission(values)) {
          const error = new Error('Please complete all required fields');
          setError(error.message);
          throw error;
        }

        if (!values.banners.placements.some((p) => p.resolutions.length > 0)) {
          const error = new Error(
            'At least one placement must have a resolution selected',
          );
          setError(error.message);
          throw error;
        }

        setLoading(true);
        setError(null);

        const payload = {
          ...values.adDetails,
          destinationData: values.destination,
          bannerData: {
            group: values.banners?.group?._id,
            platforms: values.banners?.platforms,
            placements: values.banners.placements.map((p) => ({
              id: p.id,
              resolutions: p.resolutions,
            })),
          },
        };

        const response = await fetchFbInstance('/createFacebookAds', {
          method: 'POST',
          body: JSON.stringify({ adData: payload, bannerSetId: id }),
        });

        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to create Facebook ad');
        }
        setSuccess(true);
        return data;
      } catch (error) {
        console.error('Error creating Facebook ad:', error);
        setError(error.message || error || 'An unexpected error occurred');
        throw error;
      } finally {
        setLoading(false);
      }
    },
    [validateSubmission, id],
  );

  return {
    bannerSet,
    availableResolutions,
    currentStep,
    setCurrentStep,
    handleSubmit,
    loading,
    handleStepComplete,
    validateSubmission,
    error,
    success,
    initLoading,
    loggedIn,
  };
};
