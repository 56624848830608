import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import ResolutionButtons from '../../../../../../Components/FormComponents/ResolutionButtons';
import ErrorFormBox from '../../../../../../Components/FormComponents/ErrorBox';
import { Dispatcher, Events } from '../../../../../../Components/Events';
import NavigationButtons from '../../NavigationButtons';
import { useFonts } from '../../../../../../context/FontsContext';
import defaultTextItemOptions from '../../../../../../shared/defaults/textItemOptions';
import defaultTextTags from '../../../../../../shared/defaults/textTags';
import defaultTextPositions from '../../../../../../shared/defaults/textPositions';
import VariationStatusEnum from '../../../../../../shared/enums/variationStatus';
import styles from './TextModal.module.css';
import ContentSection from './ContentSection';
import AdvancedSection from './AdvancedSection';
import ActionsButtons from './ActionsButtons';

const BannerTextModal = ({
  resolutions,
  options,
  variations: initialVariations,
  edit,
  id,
  closeModal,
  onSave,
  selectedResolution: initialSelectedResolution,
}) => {
  const { fonts, uploadFont } = useFonts();

  const [variations, setVariations] = useState(() => {
    if (initialVariations) {
      return initialVariations;
    }

    const initialVariationsObj = {};
    resolutions.forEach((resolution) => {
      initialVariationsObj[resolution] = {
        status: VariationStatusEnum.ENABLED,
        options: { ...defaultTextItemOptions, ...options },
      };
    });
    return initialVariationsObj;
  });
  const [selectedResolution, setSelectedResolution] = useState(
    initialSelectedResolution ?? resolutions[0],
  );
  const [mainResolution] = useState(resolutions[0]);
  const [disabledResolutions, setDisabledResolutions] = useState(() => {
    if (initialVariations) {
      return Object.entries(initialVariations)
        .filter(([, value]) => value.status === VariationStatusEnum.DISABLED)
        .map(([resolution]) => resolution);
    }
    return [];
  });
  const [errorResolutions, setErrorResolutions] = useState([]);
  const [contentError, setContentError] = useState('');
  const [toggleCustomCss, setToggleCustomCss] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const currentOptions = variations[selectedResolution]?.options;
    if (currentOptions?.style || currentOptions?.class) {
      setToggleCustomCss(true);
    }
  }, [selectedResolution, variations]);

  useEffect(() => {
    const shouldBeDisabled = disabledResolutions.includes(selectedResolution);
    setIsDisabled(shouldBeDisabled);
  }, [selectedResolution, disabledResolutions]);

  const handleResolutionToggle = (resolution) => {
    setSelectedResolution(resolution);
  };

  const handlePrevResolution = () => {
    const currentIndex = resolutions.indexOf(selectedResolution);
    if (currentIndex > 0) {
      const prevResolution = resolutions[currentIndex - 1];
      handleResolutionToggle(prevResolution);
    }
  };

  const handleNextResolution = () => {
    const currentIndex = resolutions.indexOf(selectedResolution);
    const currentContent = variations[selectedResolution]?.options?.content;
    const hasValidContent = currentContent && currentContent.trim() !== '';

    if (!hasValidContent && !disabledResolutions.includes(selectedResolution)) {
      setContentError('Content is required');
      return;
    }

    setContentError('');
    if (currentIndex < resolutions.length - 1) {
      const nextResolution = resolutions[currentIndex + 1];
      handleResolutionToggle(nextResolution);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    if (onSave) {
      onSave(id, variations);
      return;
    }

    Dispatcher.dispatch({
      event: edit ? Events.UPDATE_BANNER_ELEMENT : Events.ADD_BANNER_ELEMENT,
      payload: {
        id,
        type: 'BannerTextItem',
        variations,
      },
    });

    closeModal();
  };

  const validateForm = () => {
    setErrorResolutions([]);
    const errorResolutions = [];
    resolutions.forEach((resolution) => {
      if (disabledResolutions.includes(resolution)) {
        return;
      }

      const content = variations[resolution]?.options?.content;
      if (!content || content.trim() === '') {
        errorResolutions.push(resolution);
      }
    });

    setErrorResolutions(errorResolutions);

    return errorResolutions.length === 0;
  };

  const currentOptions =
    variations[selectedResolution]?.options || defaultTextItemOptions;

  const setContent = (value) => {
    const newContent = value;

    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          content: newContent,
        },
      },
    }));

    if (newContent && newContent.trim() !== '') {
      setContentError('');
    }
  };

  const setFont = (value) => {
    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          font: value,
        },
      },
    }));
  };

  const setTag = (value) => {
    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          tag: value,
        },
      },
    }));
  };

  const setSize = (value) => {
    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          size: value,
        },
      },
    }));
  };

  const setLineHeight = (value) => {
    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          lineHeight: value,
        },
      },
    }));
  };

  const setColor = (value) => {
    setVariations((prevVariations) => {
      const newVariations = { ...prevVariations };

      newVariations[selectedResolution] = {
        ...newVariations[selectedResolution],
        options: {
          ...newVariations[selectedResolution].options,
          color: value,
        },
      };

      return newVariations;
    });
  };

  const setClass = (value) => {
    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          class: value,
        },
      },
    }));
  };

  const setStyle = (value) => {
    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          style: value,
        },
      },
    }));
  };

  const handleCopyAdvancedToAll = () => {
    const mainOptions = variations[mainResolution].options;
    const advancedOptions = {
      style: mainOptions.style || '',
      class: mainOptions.class || '',
    };

    setVariations((prevVariations) => {
      const newVariations = { ...prevVariations };
      resolutions.forEach((resolution) => {
        if (!disabledResolutions.includes(resolution)) {
          newVariations[resolution] = {
            ...newVariations[resolution],
            options: {
              ...newVariations[resolution].options,
              style: advancedOptions.style,
              class: advancedOptions.class,
            },
          };
        }
      });
      return newVariations;
    });

    toast.success('Copied');
  };

  const handleCopyAdvancedFromMain = (targetResolution) => {
    const mainOptions = variations[mainResolution].options;
    const advancedOptions = {
      style: mainOptions.style || '',
      class: mainOptions.class || '',
    };

    setVariations((prevVariations) => ({
      ...prevVariations,
      [targetResolution]: {
        ...prevVariations[targetResolution],
        options: {
          ...prevVariations[targetResolution].options,
          style: advancedOptions.style,
          class: advancedOptions.class,
        },
      },
    }));

    toast.success('Copied');
  };

  const handleDisable = (resolution) => {
    const isCurrentlyDisabled = disabledResolutions.includes(resolution);

    setDisabledResolutions((prev) =>
      isCurrentlyDisabled
        ? prev.filter((r) => r !== resolution)
        : [...prev, resolution],
    );

    setVariations((prevVariations) => ({
      ...prevVariations,
      [resolution]: {
        ...prevVariations[resolution],
        status: isCurrentlyDisabled
          ? VariationStatusEnum.ENABLED
          : VariationStatusEnum.DISABLED,
      },
    }));

    if (!isCurrentlyDisabled) {
      setErrorResolutions((prev) => prev.filter((r) => r !== resolution));
      if (resolution === selectedResolution) {
        setContentError('');
      }
    }

    if (resolution === selectedResolution) {
      setIsDisabled(!isCurrentlyDisabled);
    }
  };

  const handleCopyToAll = () => {
    const mainOptions = variations[mainResolution].options;
    const basicOptions = {
      content: mainOptions.content,
      font: mainOptions.font,
      size: mainOptions.size,
      tag: mainOptions.tag,
      color: mainOptions.color,
      position: mainOptions.position,
    };

    setVariations((prevVariations) => {
      const newVariations = { ...prevVariations };
      resolutions.forEach((resolution) => {
        if (!disabledResolutions.includes(resolution)) {
          newVariations[resolution] = {
            ...newVariations[resolution],
            status: VariationStatusEnum.ENABLED,
            options: {
              ...newVariations[resolution].options,
              ...basicOptions,
            },
          };
        }
      });
      return newVariations;
    });

    toast.success('Copied');
  };

  const handleCopyFromMain = (targetResolution) => {
    const mainOptions = variations[mainResolution].options;
    const basicOptions = {
      content: mainOptions.content,
      font: mainOptions.font,
      size: mainOptions.size,
      tag: mainOptions.tag,
      color: mainOptions.color,
      position: mainOptions.position,
    };

    setVariations((prevVariations) => ({
      ...prevVariations,
      [targetResolution]: {
        ...prevVariations[targetResolution],
        status: VariationStatusEnum.ENABLED,
        options: {
          ...prevVariations[targetResolution].options,
          ...basicOptions,
        },
      },
    }));

    toast.success('Copied');
  };

  const setPosition = (value) => {
    setVariations((prevVariations) => ({
      ...prevVariations,
      [selectedResolution]: {
        ...prevVariations[selectedResolution],
        options: {
          ...prevVariations[selectedResolution].options,
          position: value,
        },
      },
    }));
  };

  const handleUploadFont = async (file) => {
    return toast.promise(uploadFont(file), {
      loading: 'Uploading font...',
      success: (data) => `Font "${data.name}" uploaded successfully!`,
      error: (err) => err.message || 'Failed to upload font',
    });
  };

  return (
    <div className={styles.root}>
      <form onSubmit={onSubmit} className={styles.form}>
        <ResolutionButtons
          networkResolutions={resolutions}
          hideLabel
          selectedResolutions={[selectedResolution]}
          onResolutionToggle={handleResolutionToggle}
          disabledResolutions={disabledResolutions}
          errorResolutions={errorResolutions}
        />
        <ActionsButtons
          isMainResolution={selectedResolution === mainResolution}
          selectedResolution={selectedResolution}
          disabledResolutions={disabledResolutions}
          handleCopyToAll={handleCopyToAll}
          handleCopyFromMain={handleCopyFromMain}
          handleDisable={handleDisable}
          isDisabled={isDisabled}
        />
        <ContentSection
          currentOptions={currentOptions}
          fontsList={fonts}
          onFontChange={(e) => setFont(e.target.value)}
          onSizeChange={(e) => setSize(e.target.value)}
          onLineHeightChange={(e) => setLineHeight(e.target.value)}
          colorProps={{
            label: 'Color',
            color: currentOptions.color,
            onChange: (color) => setColor(color),
          }}
          content={currentOptions.content}
          onChange={(e) => setContent(e.target.value)}
          error={contentError}
          isDisabled={isDisabled}
          textTags={defaultTextTags}
          onTagChange={(e) => setTag(e.target.value)}
          textPositions={defaultTextPositions}
          onPositionChange={(e) => setPosition(e.target.value)}
          onUploadFont={handleUploadFont}
        />
        <AdvancedSection
          isOpen={toggleCustomCss}
          onToggle={() => setToggleCustomCss(!toggleCustomCss)}
          isMainResolution={selectedResolution === mainResolution}
          selectedResolution={selectedResolution}
          onCopyToAll={() => handleCopyAdvancedToAll()}
          onCopyFromMain={() => handleCopyAdvancedFromMain(selectedResolution)}
          currentOptions={currentOptions}
          onClassChange={(e) => setClass(e.target.value)}
          onStyleChange={(e) => setStyle(e.target.value)}
          isDisabled={isDisabled}
        />
        {errorResolutions.length > 0 && (
          <ErrorFormBox
            message={`Please upload content for all resolutions. Missing: ${errorResolutions.join(
              ', ',
            )}`}
          />
        )}
        <NavigationButtons
          selectedResolution={selectedResolution}
          availableResolutions={resolutions}
          onPrevious={handlePrevResolution}
          onNext={handleNextResolution}
          onSubmit={onSubmit}
          submitLabel={edit ? 'Update' : 'Save'}
          isDisabled={isDisabled}
        />
      </form>
    </div>
  );
};

export default BannerTextModal;
