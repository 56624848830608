import React, { useEffect, useState } from 'react';
import { Trash3 } from 'react-bootstrap-icons';
import { SketchPicker } from 'react-color';
import toast from 'react-hot-toast';
import { MdClose } from 'react-icons/md';
import Grid from '@material-ui/core/Grid';
import GeneralModalStyles from '../../../styles/GeneralModalStyles';
import { useGalleryContext } from '../../Gallery.context';
import FlexContainer from '../../../styles/FlexContainer';
import Title from '../../../styles/TitleStyle';
import Input from '../../../FormComponents/Input';
import Button from '../../../FormComponents/Button';
import {
  ButtonSizeEnum,
  ButtonVariantEnum,
} from '../../../../shared/enums/buttonEnum';
import { GroupColorBox } from '../../GalleryCard/styled/GroupColorBox';
import fetchInstance from '../../../../utils/fetchInstance';
import gallerySidebarStyles from '../GallerySidebar.module.scss';

/**
 * @typedef {Object} ManageGroupsModalProps
 * @property {(updated?: boolean) => void} handleCloseModal
 */

/**
 *
 * @param {ManageGroupsModalProps} props
 * @returns {Element}
 * @constructor
 */
export const ManageGroupsModal = (props) => {
  const { handleCloseModal } = props;

  const { bannerGroups, bannerSetId } = useGalleryContext();

  const [bannerGroupsToAdd, setBannerGroupsToAdd] = useState([]);
  const [bannerGroupsToUpdate, setBannerGroupsToUpdate] = useState([]);
  const [bannerGroupsIdsToRemove, setBannerGroupsIdsToRemove] = useState([]);

  const [allBannerGroups, setAllBannerGroups] = useState([]);

  const [sketchOpenMap, setSketchOpenMap] = useState({});

  useEffect(() => {
    const filtered = bannerGroups
      .filter(
        (group) =>
          group.name.toLowerCase() !== 'rejected' &&
          !bannerGroupsIdsToRemove.includes(group._id),
      )
      .map((group) => {
        const updatedGroup = bannerGroupsToUpdate.find(
          (updatedGroup) => updatedGroup._id === group._id,
        );

        return updatedGroup ? updatedGroup : group;
      });

    setAllBannerGroups(filtered.concat(bannerGroupsToAdd));
  }, [
    bannerGroups,
    bannerGroupsToAdd,
    bannerGroupsIdsToRemove,
    bannerGroupsToUpdate,
  ]);

  const [newGroupData, setNewGroupData] = useState({
    name: '',
    color: '#000000',
  });

  const handleAddNewGroup = async (event) => {
    event.stopPropagation();

    const temporaryId = new Date().getTime();

    setBannerGroupsToAdd((prevState) => [
      ...prevState,
      { ...newGroupData, temporaryId },
    ]);
  };

  const handleChangeNewGroup = (event, { name, color }) => {
    event.stopPropagation();

    setNewGroupData((prevState) => ({
      name: name != null ? name : prevState.name,
      color: color != null ? color : prevState.color,
    }));
  };

  const handleChangeGroup = (idx, color, event) => {
    event.stopPropagation();

    const groupToUpdate = allBannerGroups[idx];

    if (groupToUpdate._id) {
      setBannerGroupsToUpdate((prevState) => {
        let updated = false;

        const updatedArray = prevState.map((group) => {
          if (group._id === groupToUpdate._id) {
            updated = true;
            return { ...group, color: color.hex };
          }
          return group;
        });

        if (updated) {
          return updatedArray;
        }
        return [...prevState, { ...groupToUpdate, color: color.hex }];
      });
      return;
    }

    setBannerGroupsToAdd((prevState) => {
      const updatedGroup = { ...groupToUpdate, color: color.hex };

      return prevState.map((group) =>
        group.temporaryId === groupToUpdate.temporaryId ? updatedGroup : group,
      );
    });
  };

  const handleRemoveGroup = async (idx) => {
    const groupToRemove = allBannerGroups[idx];

    if (groupToRemove._id) {
      setBannerGroupsIdsToRemove((prev) => [...prev, groupToRemove._id]);
      return;
    }

    setBannerGroupsToAdd((prev) =>
      prev.filter((group) => group.temporaryId !== groupToRemove.temporaryId),
    );
  };

  const handleSave = async () => {
    const createPromises = bannerGroupsToAdd.map((group) => {
      const { name, color } = group;

      return fetchInstance('/bannerGroup', {
        method: 'POST',
        body: JSON.stringify({
          bannerGroup: { bannerSet: bannerSetId, name, color },
        }),
      });
    });
    const updatePromises = bannerGroupsToUpdate.map((group) => {
      const { _id, name, color } = group;

      return fetchInstance(`/updateBannerGroup/${_id}`, {
        method: 'PUT',
        body: JSON.stringify({ bannerGroup: { name, color } }),
      });
    });
    const deletePromises = bannerGroupsIdsToRemove.map((groupId) =>
      fetchInstance(`/deleteBannerGroup/${groupId}`, {
        method: 'DELETE',
      }),
    );

    try {
      const responses = await Promise.all([
        ...createPromises,
        ...updatePromises,
        ...deletePromises,
      ]);

      const responsesJsons = await Promise.all(
        responses.map((res) => res.json()),
      );

      if (responsesJsons.every((data) => data.error)) {
        toast.error(responsesJsons[0].message);
        return;
      }

      toast.success('Changes saved successfully!');

      handleCloseModal(true);
    } catch (err) {
      toast.error('Could not save changes');
    }
  };

  return (
    <GeneralModalStyles className="modal-wrapper">
      <div className="modal-body" style={{ width: 'auto', maxWidth: '400px' }}>
        <div onClick={handleCloseModal} className="modal-close">
          <MdClose className="action-button button-delete" />
        </div>

        <Title>Manage Groups</Title>

        <FlexContainer direction="column">
          <FlexContainer gap="5px" alignItems="center">
            {allBannerGroups.map((group, idx) => (
              <Grid
                key={`group-${group._id || group.temporaryId}`}
                container
                spacing={2}
                alignItems="center"
              >
                <Grid item xs={1}>
                  <GroupColorBox
                    editable
                    color={group.color}
                    size="24px"
                    onClick={() => {
                      setSketchOpenMap((prevState) => {
                        const groupId = group._id || group.temporaryId;

                        return {
                          ...prevState,
                          [groupId]: !prevState[groupId],
                        };
                      });
                    }}
                  >
                    {sketchOpenMap[group._id || group.temporaryId] && (
                      <div
                        className={gallerySidebarStyles.sketchWrapper}
                        onClick={(event) => {
                          event.stopPropagation();
                        }}
                      >
                        <SketchPicker
                          color={group.color}
                          onChange={(color, event) =>
                            handleChangeGroup(idx, color, event)
                          }
                          disableAlpha={true}
                        />
                      </div>
                    )}
                  </GroupColorBox>
                </Grid>
                <Grid item xs={9}>
                  {group.name}
                </Grid>
                <Grid item xs={2}>
                  <Button
                    variant={ButtonVariantEnum.TEXT}
                    size={ButtonSizeEnum.XS}
                    onClick={() => handleRemoveGroup(idx)}
                  >
                    <Trash3 width={16} height={16} />
                  </Button>
                </Grid>
              </Grid>
            ))}
          </FlexContainer>

          <FlexContainer gap="5px" alignItems="center">
            <Input
              name="name"
              value={newGroupData.name}
              onChange={(event) => {
                const newValue = event.target.value;

                handleChangeNewGroup(event, { name: newValue });
              }}
            />
            <GroupColorBox
              editable
              color={newGroupData.color || '#000000'}
              size="24px"
              onClick={() => {
                setSketchOpenMap((prevState) => ({
                  ...prevState,
                  new: !prevState.new,
                }));
              }}
            >
              {sketchOpenMap.new && (
                <div className={gallerySidebarStyles.sketchWrapper}>
                  <SketchPicker
                    color={newGroupData.color || '#000000'}
                    onChange={(color, event) =>
                      handleChangeNewGroup(event, { color: color.hex })
                    }
                    disableAlpha={true}
                  />
                </div>
              )}
            </GroupColorBox>
            <Button onClick={handleAddNewGroup}>Add group</Button>
          </FlexContainer>

          <FlexContainer justifyContent="center">
            <Button onClick={handleSave}>Save</Button>
          </FlexContainer>
        </FlexContainer>
      </div>
    </GeneralModalStyles>
  );
};
