import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import toast from 'react-hot-toast';
import { nanoid } from 'nanoid';
import fetchInstance from '../../../utils/fetchInstance';
import { prepareIdFromMongo } from '../../../utils/transformMongoId';
import BannerElementType from '../../../shared/enums/bannerElementType';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import handleBannerSetRedirect from '../../../utils/handleBannerSetRedirect';
import VariationStatusEnum from '../../../shared/enums/variationStatus';
import { DefaultElements, MixerElement } from './Consts/DefaultElements';
import DefaultRootOptions from './Consts/DefaultRootOptions';
import validateElementVariations from './Helpers/validateElementVariations';

const MixerContext = createContext(null);

export const MixerProvider = ({ children }) => {
  const { id } = useParams();
  const history = useHistory();
  const [bannerSet, setBannerSet] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedResolution, setSelectedResolution] = useState(null);
  const [elements, setElements] = useState({});
  const [selectedElement, setSelectedElement] = useState(DefaultElements.root);
  const [previewOptions, setPreviewOptions] = useState({});
  const [rootOptions, setRootOptions] = useState(DefaultRootOptions);
  const [customBackgroundVariants, setCustomBackgroundVariants] = useState([]);
  const [elementVariants, setElementVariants] = useState([]);

  const isMounted = useRef(true);
  const previewRef = useRef(null);
  const selectedResolutionRef = useRef(null);
  const allResolutionsRef = useRef([]);
  const originalOptionValues = useRef({});
  const elementFiles = useRef({});

  const allResolutions = useMemo(() => {
    return bannerSet?.resolutions?.map((res) => `${res.width}x${res.height}`);
  }, [bannerSet]);

  useEffect(() => {
    allResolutionsRef.current = allResolutions || [];
  }, [allResolutions]);

  useEffect(() => {
    const fetchBannerSet = async () => {
      try {
        setIsLoading(true);
        setError(null);

        const response = await fetchInstance(
          `/getBannerSet/${id}?aggregate=true`,
        );
        const data = await response.json();

        if (!response.ok) {
          throw new Error(data.message || 'Failed to fetch banner set');
        }

        if (handleBannerSetRedirect(data.status, data._id, history)) {
          return;
        }

        if (isMounted.current) {
          setBannerSet(data);
          setSelectedElement(DefaultElements.root);

          if (data.resolutions?.length > 0) {
            const firstRes = data.resolutions[0];
            setSelectedResolution(`${firstRes.width}x${firstRes.height}`);
          }
        }

        const mixerDataResponse = await fetchInstance(`/getMixerData/${id}`);
        const mixerData = await mixerDataResponse.json();
        if (mixerData.backgroundOptions) {
          setCustomBackgroundVariants(
            prepareIdFromMongo(mixerData.backgroundOptions),
          );
        }
        if (mixerData.rootOptions) {
          setRootOptions(prepareIdFromMongo(mixerData.rootOptions));
        }
        if (mixerData.elements) {
          setElementVariants(prepareIdFromMongo(mixerData.elements));
        }
      } catch (error) {
        toast.error('Error fetching mixer data. Please try again.');
        console.error('Error fetching mixer:', error);
        if (isMounted.current) {
          setError('Failed to load mixer. Please try again.');
        }
      } finally {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    };

    fetchBannerSet();

    return () => {
      isMounted.current = false;
    };
  }, [id]);

  const handleResolutionToggle = (resolution) => {
    selectedResolutionRef.current = resolution;
    setSelectedResolution(resolution);
  };

  const previewOption = ({ elementName, option, value }) => {
    const frame = previewRef.current?.contentDocument;
    if (!frame) return;

    const element = frame.querySelector(elementName);
    if (!element) return;

    if (option === 'innerHTML' || option === 'innerText') {
      element[option] = value;
    } else if (element.style[option] !== undefined) {
      element.style[option] = value;
    } else if (element.hasAttribute(option)) {
      element.setAttribute(option, value);
    }
  };

  const elementCategories = useMemo(() => {
    if (!bannerSet) return [];

    return [
      {
        ...DefaultElements.root,
        options: rootOptions,
      },
      ...(Object.values(bannerSet?.banners?.[0]?.backgrounds || {}).some(
        (bg) => bg.src,
      )
        ? [
            {
              ...DefaultElements.background,
              options: {
                src: [bannerSet?.banners?.[0]?.background?.src || ''],
                opacity: ['1'],
                fit: ['cover'],
              },
            },
          ]
        : []),
      ...(bannerSet?.banners?.[0]?.elements?.map((element) => ({
        id: element._id,
        name: element.name || element.type,
        type: element.type,
        variations: element.variations,
      })) || []),
      MixerElement,
    ];
  }, [bannerSet, rootOptions]);

  const handleNextElement = () => {
    if (
      selectedElement.id === DefaultElements.background.id ||
      selectedElement.type === BannerElementType.IMAGE
    ) {
      if (
        !validateElementVariations({
          element: selectedElement,
          DefaultElements,
          customBackgroundVariants,
          elementVariants,
        })
      ) {
        return;
      }
    }

    const currentIndex = elementCategories.findIndex(
      (cat) => cat.id === selectedElement.id,
    );
    if (currentIndex < elementCategories.length - 1) {
      const nextCategory = elementCategories[currentIndex + 1];
      setSelectedElement({
        id: nextCategory.id,
        name: nextCategory.name,
        ...(nextCategory.type && { type: nextCategory.type }),
      });
    }
  };

  const handlePrevElement = () => {
    const currentIndex = elementCategories.findIndex(
      (cat) => cat.id === selectedElement.id,
    );
    if (currentIndex > 0) {
      const prevCategory = elementCategories[currentIndex - 1];
      setSelectedElement({
        id: prevCategory.id,
        name: prevCategory.name,
        ...(prevCategory.type && { type: prevCategory.type }),
      });
    }
  };

  const handleResetPreview = () => {
    if (!selectedElement.id) return;

    const elementInState = elements[selectedElement.id];
    const options = Object.keys(elementInState);

    options.forEach((optionName) => {
      if (optionName === 'pickedColor') return;
      previewOption({
        elementName: selectedElement.id,
        option: optionName,
        lastValue:
          originalOptionValues.current[selectedElement.id][optionName][0],
      });
    });
  };

  const handleRestartButton = () => {
    ConfirmModal({
      title: 'Are you sure you want to restart?',
      subMessage: [
        'This will remove all elements from all resolutions.',
        'This action cannot be undone.',
      ],
      confirmText: 'Restart',
      cancelText: 'Cancel',
      isDanger: true,
      onConfirm: handleRestart,
    });
  };

  const handleRestart = () => {
    setCustomBackgroundVariants([]);
    setElementVariants([]);
    setRootOptions(DefaultRootOptions);
    setPreviewOptions({});
    setSelectedElement(DefaultElements.root);
    toast.success('All custom variants have been reset');
  };

  const handleOptionChange = (optionName, value) => {
    if (!selectedElement.id) return;
    if (optionName === 'backgrounds') {
      setBannerSet((prev) => ({
        ...prev,
        banners: [
          {
            ...prev.banners[0],
            backgrounds: value,
          },
          ...prev.banners.slice(1),
        ],
      }));
    } else if (selectedElement.id === DefaultElements.root.id) {
      setRootOptions((prev) => ({
        ...prev,
        [optionName]: value,
      }));
    } else {
      if (Array.isArray(value)) {
        setElements((prev) => ({
          ...prev,
          [selectedElement.id]: {
            ...prev[selectedElement.id],
            [optionName]: value,
          },
        }));
      } else {
        setElements((prev) => ({
          ...prev,
          [selectedElement.id]: {
            ...prev[selectedElement.id],
            [optionName]: [value],
          },
        }));
      }
    }
  };

  const handleAddCustomBackgroundVariant = (newVariant) => {
    setCustomBackgroundVariants((prev) => [...prev, newVariant]);
  };

  const handleRemoveCustomBackgroundVariant = (variantId) => {
    setCustomBackgroundVariants((prev) =>
      prev.filter((variant) => variant.id !== variantId),
    );
  };

  const handleUpdateBackgroundVariant = ({
    resolution,
    setId,
    filePath,
    status,
    isActivePreviewSet,
  }) => {
    setCustomBackgroundVariants((prev) => {
      const newVariants = prev.map((variant) => {
        if (variant.id === setId) {
          return {
            ...variant,
            variations: {
              ...variant.variations,
              [resolution]: {
                ...variant.variations[resolution],
                ...(filePath !== undefined ? { src: filePath } : {}),
                ...(status ? { status } : {}),
                ...(status === VariationStatusEnum.DISABLED ? { src: '' } : {}),
              },
            },
          };
        }
        return variant;
      });

      if (isActivePreviewSet) {
        const activeVariant = newVariants.find((v) => v.id === setId);
        if (activeVariant) {
          handlePreviewOption({
            name: 'backgroundImages',
            value: activeVariant.variations,
          });
        }
      }

      return newVariants;
    });
  };

  const handlePreviewOption = ({ name, value, index, forceRemove = false }) => {
    setPreviewOptions((prev) => {
      if (forceRemove) {
        // eslint-disable-next-line no-unused-vars
        const { [name]: _, ...rest } = prev;
        return rest;
      }

      if (name === 'backgroundImages') {
        return { ...prev, backgroundImages: value };
      }

      if (name === 'elementImages' || name === 'elementText') {
        const { elementId, variantId, variations } = value;

        if (!elementId) {
          // eslint-disable-next-line no-unused-vars
          const { elements, ...rest } = prev;
          return rest;
        }

        if (!variantId) {
          const { elements: currentElements = {}, ...rest } = prev;
          // eslint-disable-next-line no-unused-vars
          const { [elementId]: _, ...remainingElements } = currentElements;
          return {
            ...rest,
            ...(Object.keys(remainingElements).length > 0
              ? { elements: remainingElements }
              : {}),
          };
        }

        const variationsToUse =
          variations ||
          elementVariants.find((v) => v.id === variantId)?.variations;

        const newState = {
          ...prev,
          elements: {
            ...(prev.elements || {}),
            [elementId]: {
              variantId,
              variations: variationsToUse,
              type:
                name === 'elementText'
                  ? BannerElementType.TEXT
                  : BannerElementType.IMAGE,
            },
          },
        };

        return newState;
      }

      if (typeof index === 'number') {
        const currentValue = prev[name];
        if (currentValue?.value === value && currentValue?.index === index) {
          // eslint-disable-next-line no-unused-vars
          const { [name]: _, ...rest } = prev;
          return rest;
        }
        return { ...prev, [name]: { value, index } };
      }

      if (prev[name] === value) {
        // eslint-disable-next-line no-unused-vars
        const { [name]: _, ...rest } = prev;
        return rest;
      }

      return { ...prev, [name]: value };
    });
  };

  const resetPreview = () => {
    setPreviewOptions({});
  };

  const isEnabled = (type, variation) => {
    if (type === BannerElementType.IMAGE && variation?.options?.src) {
      return VariationStatusEnum.ENABLED;
    }
    return VariationStatusEnum.FORCE_DISABLED;
  };

  const handleAddElementVariant = (variantData = null) => {
    const currentElement = bannerSet.banners[0].elements?.find(
      (el) => el._id === selectedElement.id,
    );

    if (!currentElement) return;

    if (variantData) {
      setElementVariants((prev) => [
        ...prev,
        {
          baseId: selectedElement.id,
          type: currentElement.type,
          id: variantData.id || nanoid(),
          variations: variantData.variations,
        },
      ]);
      return;
    }

    const variations = allResolutions.reduce((acc, resolution) => {
      acc[resolution] = {
        src: '',
        status: isEnabled(
          currentElement.type,
          bannerSet?.banners[0]?.elements?.find(
            (el) => el._id === selectedElement.id,
          )?.variations?.[resolution],
        ),
        id: nanoid(),
      };
      return acc;
    }, {});

    const newVariant = {
      id: nanoid(),
      baseId: selectedElement.id,
      type: currentElement.type,
      variations,
    };

    setElementVariants((prev) => [...prev, newVariant]);
  };

  const handleAddTextElementVariant = ({ id, variations }) => {
    const newVariant = {
      id: nanoid(),
      baseId: id,
      type: BannerElementType.TEXT,
      variations,
    };
    setElementVariants((prev) => [...prev, newVariant]);
  };

  const handleRemoveElementVariant = (variantId) => {
    const variantToRemove = elementVariants.find((v) => v.id === variantId);

    if (variantToRemove) {
      const elementId = variantToRemove.baseId;

      if (previewOptions.elements?.[elementId]?.variantId === variantId) {
        setPreviewOptions((prev) => {
          const { elements: currentElements = {}, ...rest } = prev;
          // eslint-disable-next-line no-unused-vars
          const { [elementId]: _, ...remainingElements } = currentElements;

          return {
            ...rest,
            ...(Object.keys(remainingElements).length > 0
              ? { elements: remainingElements }
              : {}),
          };
        });
      }
    }

    setElementVariants((prev) =>
      prev.filter((variant) => variant.id !== variantId),
    );
  };

  const handleUpdateElementVariant = ({
    variantId,
    resolution,
    updates,
    type,
  }) => {
    if (type === BannerElementType.TEXT) {
      setElementVariants((prev) =>
        prev.map((variant) =>
          variant.id === variantId ? { ...variant, ...updates } : variant,
        ),
      );
      return;
    }

    setElementVariants((prev) => {
      const newVariants = prev.map((variant) => {
        if (variant.id === variantId) {
          const updatedVariant = {
            ...variant,
            variations: {
              ...variant.variations,
              [resolution]: {
                ...variant.variations[resolution],
                ...updates,
                ...(updates.status === VariationStatusEnum.DISABLED
                  ? { src: '' }
                  : {}),
              },
            },
          };

          const elementId = variant.baseId;
          if (previewOptions.elements?.[elementId]?.variantId === variantId) {
            const newPreviewData = {
              elementId,
              variantId,
              variations: updatedVariant.variations,
            };

            setTimeout(() => {
              handlePreviewOption({
                name: 'elementImages',
                value: newPreviewData,
              });
            }, 0);
          }

          return updatedVariant;
        }
        return variant;
      });

      return newVariants;
    });
  };

  const handleSaveData = async () => {
    toast.promise(handleSave(), {
      loading: 'Saving...',
      success: <b>Mixer data saved!</b>,
      error: <b>Could not save.</b>,
    });
  };

  const handleStartMixing = async () => {
    toast.promise(startMixing(), {
      loading: 'Starting mixing...',
      success: <b>Mixing started!</b>,
      error: <b>Could not start mixing.</b>,
    });
  };

  const startMixing = async () => {
    try {
      await handleSave();
      const response = await fetchInstance(`/startMixing/${bannerSet._id}`, {
        method: 'POST',
      });
      const data = await response.json();
      if (!data.success) {
        throw new Error('Failed to start mixing');
      }
      history.replace(`/mixing/${bannerSet._id}`);
    } catch (error) {
      throw new Error('Failed to start mixing');
    }
  };

  const handleSave = async () => {
    try {
      const submissionData = {
        rootOptions: rootOptions,
        backgroundOptions: customBackgroundVariants,
        elements: elementVariants,
      };

      const response = await fetchInstance(
        `/updateMixerData/${bannerSet._id}`,
        {
          method: 'PUT',
          body: JSON.stringify({
            mixerData: submissionData,
          }),
        },
      );
      const updatedMixerData = await response.json();
      if (!updatedMixerData.success) {
        throw new Error('Failed to update mixer data');
      }
      if (updatedMixerData.backgroundOptions) {
        setCustomBackgroundVariants(
          prepareIdFromMongo(updatedMixerData.backgroundOptions),
        );
      }
      if (updatedMixerData.rootOptions) {
        setRootOptions(prepareIdFromMongo(updatedMixerData.rootOptions));
      }
      if (updatedMixerData.elements) {
        setElementVariants(prepareIdFromMongo(updatedMixerData.elements));
      }
    } catch (error) {
      throw new Error('Failed to update mixer data');
    }
  };

  const value = {
    // State
    bannerSet,
    isLoading,
    error,
    selectedResolution,
    elements,
    selectedElement,
    allResolutions,
    rootOptions,
    customBackgroundVariants,
    elementVariants,

    // Refs
    previewRef,
    selectedResolutionRef,
    allResolutionsRef,
    originalOptionValues: originalOptionValues.current,
    elementFiles: elementFiles.current,

    // Actions
    setSelectedResolution,
    handleResolutionToggle,
    handleNextElement,
    handlePrevElement,
    handleResetPreview,
    handleRestartButton,
    handleOptionChange,
    handleAddCustomBackgroundVariant,
    handleRemoveCustomBackgroundVariant,
    handleUpdateBackgroundVariant,
    handleAddElementVariant,
    handleRemoveElementVariant,
    handleUpdateElementVariant,
    handleSaveData,
    handleAddTextElementVariant,
    handleStartMixing,

    // Expunem categoriile în context
    elementCategories,
    previewOptions,
    handlePreviewOption,
    resetPreview,
  };

  return (
    <MixerContext.Provider value={value}>{children}</MixerContext.Provider>
  );
};

export const useMixer = () => {
  const context = useContext(MixerContext);
  if (!context) {
    throw new Error('useMixer must be used within a MixerProvider');
  }
  return context;
};
